import Vue from "vue";
import Component from "vue-class-component";

@Component({ name: "App" })
export default class App extends Vue {

    get culture() {
        return this.$store.getters["appResourceStore/culture"];
    }

    get cultures() {
        return this.$store.getters["appResourceStore/cultures"];
    }

    get header() {
        return this.$store.getters["headerStore/isHeader"];
    }

    get isCultureSelectorVisible() {
        return this.$store.getters["cultureSelectorStore/isCultureSelectorVisible"];
    }

    private async created() {
        await this.$store.dispatch("appResourceStore/getCultures");

        this.setDefaultCulture();

        this.$store.dispatch("appResourceStore/getAppResources");
    }

    private setDefaultCulture() {
        this.setCultureFromQuery();

        if (!this.culture) {
            this.setCultureFromBrowser();
        }

        if (!this.culture) {
            this.$store.commit("appResourceStore/setCulture", "en-GB");
        }
    }

    private setCultureFromQuery() {
        if (this.$route.query.culture) {
            let cultures = this.cultures.filter(x => x.value.toLowerCase() === (<string>this.$route.query.culture).toLowerCase() && !x.isDisabled)
            if (cultures.length !== 0) {
                this.$store.commit("appResourceStore/setCulture", cultures[0].value);
            }
        }
    }

    private setCultureFromBrowser() {
        if (navigator.language) {
            let cultures = this.cultures.filter(x => x.value.toLowerCase() === navigator.language.toLowerCase() && !x.isDisabled)
            if (cultures.length !== 0) {
                this.$store.commit("appResourceStore/setCulture", cultures[0].value);
            }
        }
    }
};