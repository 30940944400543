import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import ResourceHelper from "@Scripts/Infrastructure/ResourceHelper";
import MediaHelper from "@Scripts/Infrastructure/MediaHelper";

@Component({ name: "TripUnitScenarios" })
export default class TripUnitScenarios extends Vue {
    private resourceHelper: any = ResourceHelper;
    private container: HTMLElement = null;
    private mediaHelper: any = MediaHelper;
    private isContainerLoading: boolean = true;
    private controlCount: number = 0;
    private controlLoadCount: number = 0;
    private scenarioItems: any[] = [];
    private scenario: any = { title: "", description: "" };
    private scenarioName: string = null;
    private scenarioData: any = null;
    private currentStep: number = 1;
    private currentAnimationName: string = null;
    private isPrevDisable: boolean = false;
    private isNextDisable: boolean = false;
    private timeIntervals: any[] = [];

    @Prop() readonly tripUnitData: any;
    @Prop() readonly isSummaryUpdate: any;

    @Watch("isSummaryUpdate") onSummaryUpdateChanged(value: boolean) {
        this.setDefaultScenario();
    }

    get culture() {
        return this.$store.getters["appResourceStore/culture"];
    }

    get appResources() {
        return this.$store.getters["appResourceStore/appResources"];
    }

    private created() {
        this.controlCount = this.tripUnitData.controls.filter(x => x.type === "Led" || x.type === "Notification").length;

        this.setScenarioItems();
    }

    private beforeDestroy() {
        this.setDefaultScenario();
    }

    private setDefaultScenario() {
        this.scenarioName = null;
        this.scenario = null;
        this.scenarioData = null;
        this.turnOffAllControls();
        this.timeIntervals.forEach(timeInterval => {
            clearTimeout(timeInterval);
        });
    }

    private setScenarioItems() {
        this.scenarioItems = this.tripUnitData.scenarios.map(x => { return { title: x.title, value: x.title } });
        this.scenarioItems.unshift({ title: [{ "culture": "en-GB", "value": "..." }], value: null });
    }

    private setScenarioConfiguration() {
        this.scenario = null;
        this.scenarioData = null;

        this.timeIntervals.forEach(timeInterval => {
            clearTimeout(timeInterval);
        });

        let scenarios = this.tripUnitData.scenarios.filter(x => x.title === this.scenarioName);

        if (scenarios.length != 0) {
            this.scenarioData = scenarios[0];
            this.scenarioData.totalStep = scenarios[0].steps.length;
            this.currentStep = 1;

            this.setStepConfiguration();
        } else {
            this.scenarioName = null;
            this.scenario = { title: "", description: "" };
            this.turnOffAllControls();
            document.getElementById("Tripunit").setAttribute("data-info", "false");
        }
    }

    private setStepConfiguration() {
        let step = this.scenarioData.steps[this.currentStep - 1];
        this.currentAnimationName = step.animationName;

        this.scenario = {
            title: step.title,
            description: step.description
        }

        this.timeIntervals.forEach(timeInterval => {
            clearTimeout(timeInterval);
        });

        this.setControlConfiguration(this.currentStep - 1);
    }

    private prevStep() {
        if (this.currentStep > 1) {
            this.currentStep--;
            this.setStepConfiguration();
        }
    }

    private nextStep() {
        if (this.currentStep < this.scenarioData.totalStep) {
            this.currentStep++;
            this.setStepConfiguration();
        }
    }

    private async setControlConfiguration(step: number) {
        this.turnOffAllControls();
        this.checkStepButton();

        const self = this;
        let scenarios = this.tripUnitData.scenarios.filter(x => x.title === this.scenarioName)[0];

        for (const configurationItem of scenarios.steps[step].configuration.controls) {
            this.timeIntervals.push(setTimeout(function () {
                let controls = self.tripUnitData.controls.filter(x => x.name === configurationItem.name);
                if (controls.length !== 0) {
                    let config = controls[0].configurations.filter(x => x.configuration === configurationItem.configuration)[0];

                    document.getElementById("Tripunit").setAttribute("data-info", "true");
                    controls[0].value = config.value;
                    controls[0].color = config.color;
                    controls[0].configuration = config.configuration;
                }
            }, configurationItem.delay));
        }
    }

    private checkStepButton() {
        this.currentStep == 1 ? this.isPrevDisable = true : this.isPrevDisable = false;
        this.currentStep < this.scenarioData.totalStep ? this.isNextDisable = false : this.isNextDisable = true;
    }

    private turnOffAllControls() {
        let controls = this.tripUnitData.controls.filter(x => x.type === "Led" || x.type === "Notification");
        for (const control of controls) {
            control.value = null;
        }
    }

    private animationLoadError() {
        this.currentAnimationName = null;
    }

    private controlLoadComplete() {
        this.controlLoadCount++;

        if (this.controlLoadCount == this.controlCount) {
            this.isContainerLoading = false;
        }
    }

    private setControlType(control: any) {
        switch (control.type) {
            case "Led": return "app-led";
            case "Notification": return "app-notification";
            default: return null;
        }
    }

    private onImageLoad(e) {
        this.container = <HTMLElement>this.$refs.container;
    }

    private showFullscreen() {
        let message = document.getElementById("Message");
        message.setAttribute("data-show", "true");

        setTimeout(function () {
            message.setAttribute("data-show", "false");
        }, 3000);
    }
};