import { DirectiveOptions } from "vue";

const directive: DirectiveOptions = {
    inserted(el, node, vnode) {
        hideOnClickOutside(el);

        function hideOnClickOutside(element) {
            const outsideClickListener = event => {
                if (event.target.className === "image") {
                    let slides = document.getElementsByClassName("swiper-button");

                    for (const slide of slides) {
                        if (slide) {
                            slide.classList.remove("hden");
                        }
                    }

                    setTimeout(function () {
                        for (const slide of slides) {
                            slide.classList.add("hden");
                        }
                    }, 2000)
                }
            }
            document.addEventListener("click", outsideClickListener);
        }

        const isVisible = elem => !!elem && !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);
    }
};

export default directive;