import Vue from "vue";
import Component from "vue-class-component";
import Axios from "axios";

@Component({ name: "TripUnitDetail" })
export default class TripUnitDetail extends Vue {
    private tripUnitName: string = this.$route.params.name;
    private tripUnitData: any = null;
    private cachedTripUnitsData: any = null;
    private isSummaryUpdate: boolean = false;
    private isSettingReset: boolean = false;
    private isPrint: boolean = false;

    get appResources() {
        return this.$store.getters["appResourceStore/appResources"];
    }

    get header() {
        return this.$store.getters["headerStore/isHeader"];
    }

    get isCultureSelectorVisible() {
        return this.$store.getters["cultureSelectorStore/isCultureSelectorVisible"];
    }

    get returnParameters() {
        return this.$store.getters["returnParametersStore/returnParameters"];
    }

    private async created() {
        this.getData();
    }

    private async getData() {
        try {
            this.tripUnitData = (await Axios.get("/data/tripunits/" + this.tripUnitName.toLowerCase() + ".json")).data;

            for (const control of this.tripUnitData.controls) {
                // Init runtime properties
                Vue.set(control, "isVisible", true);
            }

            this.cachedTripUnitsData = JSON.parse(JSON.stringify(this.tripUnitData));
        } catch (error) {
            this.$router.push({ name: "TripUnitList" });
            return;
        }
    }

    private setDefaultSettings() {
        this.tripUnitData = JSON.parse(JSON.stringify(this.cachedTripUnitsData));
        this.isSettingReset = !this.isSettingReset;
    }

    private goBack() {
        this.$router.push({ name: "TripUnitList", query: this.returnParameters }).catch(() => { });
    }

    private openPrint() {
        this.isPrint = true;
    }

    private closePrint() {
        this.isPrint = false;
    }

    private controlChangeValue() {
        this.isSummaryUpdate = !this.isSummaryUpdate;
    }
};