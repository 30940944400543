export default class HtmlHelper {
    static getTripUnitImagePath(tripUnit: any) {
        return "/data/images/tripunits/" + tripUnit.name.toLowerCase() + ".svg";
    }

    static getCircuitBreakerImagePath(circuitBreaker: any) {
        return "/data/images/circuitbreakers/" + circuitBreaker.family.replace(/\s/g, '-').toLowerCase() + "/" + circuitBreaker.name.toLowerCase() + ".jpg";
    }

    static getFamilyImagePath(circuitBreaker: any) {
        return "/data/images/circuitbreakers/" + circuitBreaker.replace(/\s/g, '-').toLowerCase() + "/family.jpg";
    }

    static getLedImagePath(led: any) {
        let type: string;
        let style = led.style.toLowerCase();

        (led.group.toLowerCase() == "power") ? type = "power-" : type = "led-";

        if (led.color) {
            style += "-" + led.color.toLowerCase();
        } else if (led.group.toLowerCase() == "power") {
            style += "-green";
        } else {
            const element = led.configurations.find(element => element.value == led.value);
            (element.color == null) ? (style += "-red") : (style += "-" + element.color.toLowerCase());
        }

        return "/data/images/leds/" + type + style + ".svg";
    }

    static getChartImagePath(name: string) {
        return "/data/images/chart/" + name.toLowerCase() + ".svg";
    }

    static getAnimationPath(name: string) {
        return "/data/images/scenarios/" + name.toLowerCase();
    }

    static getNotificationImagePath(notification: any) {
        if (notification.group.toLowerCase() !== "display") {
            return null;
        }

        if (notification.styleIcon) {
            return "/data/images/notifications/" + notification.group.toLowerCase() + "-" + notification.styleIcon.toLowerCase() + ".svg";
        }

        if (notification.style.includes(".gif")) {
            return "/data/images/notifications/" + notification.group.toLowerCase() + "-" + notification.style.toLowerCase();
        }

        return "/data/images/notifications/" + notification.group.toLowerCase() + "-" + notification.style.toLowerCase() + ".svg";
    }

    static getAlarmIconPath(name: string, isSmallDevice: boolean) {
        if (isSmallDevice) {
            name = "small_" + name;
        }
        return "/data/images/icons/alarms/" + name.toLowerCase() + ".svg";
    }

    static getIconToastPath(name: string, isSmallDevice: boolean) {
        if (isSmallDevice) {
            name = "small_" + name;
        }
        return "/data/images/icons/toast/" + name.toLowerCase() + ".svg";
    }

    static getDisplayBackgroundImagePath(name: string) {
        return "/data/images/display/backgroundImages/" + name.toLowerCase();
    }

    static getDisplayImagePath(name: string) {
        return "/data/images/display/images/" + name.toLowerCase();
    }

    static getIconScreenSettingImagePath(name: string) {
        return "/data/images/icons/screens/settings/" + name.toLowerCase();
    }

    static getManEltImagePath(control: any, isActive: boolean) {
        let index = isActive ? 1 : 0;
        return "/data/images/man-elt/" + control.configurations[index].image;
    }
}