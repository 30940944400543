import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import MediaHelper from "@Scripts/Infrastructure/MediaHelper";
import ResourceHelper from "@Scripts/Infrastructure/ResourceHelper";

@Component({ name: "TripUnitSettings" })
export default class TripUnitSettings extends Vue {
    private resourceHelper: any = ResourceHelper;
    private mediaHelper: any = MediaHelper;
    private container: HTMLElement = null;
    private isContainerLoading: boolean = true;
    private controlCount: number = 0;
    private controlLoadCount: number = 0;
    private showEdit: boolean = false;
    private isSmallDevice: boolean = false;
    private formData: any = {};
    private tripunitimage: HTMLImageElement = null;

    @Prop() readonly tripUnitData: any;
    @Prop() readonly isSummaryUpdate: boolean;
    @Prop() readonly isSettingReset: boolean;
    @Prop() readonly isPrint: boolean;

    get appResources() {
        return this.$store.getters["appResourceStore/appResources"];
    }

    get culture() {
        return this.$store.getters["appResourceStore/culture"];
    }

    get nominalCurrent() {
        return this.$store.getters["deviceSettingStore/nominalCurrent"];
    }

    private created() {
        if (this.tripUnitData.type === "Ekip Touch") {
            this.controlCount = this.tripUnitData.controls.filter(x => x.type === "Display").length;
        } else {
            this.controlCount = this.tripUnitData.controls.filter(x => x.type === "DIPSwitchList").length;
        }

        if (!this.nominalCurrent) {
            this.$store.commit("deviceSettingStore/setNominalCurrent", this.tripUnitData.nominalCurrent.value);
        }

        this.isSmallDevice = this.tripUnitData.isSmallDevice ? true : false;
    }

    private mounted() {
        this.tripunitimage = <HTMLImageElement>this.$refs.tripunitimage;
    }

    private beforeDestroy() {
        this.$store.commit("deviceSettingStore/setPinChecked", false);
        this.$store.dispatch("deviceSettingStore/resetCacheSettings");
        this.$store.commit("deviceSettingStore/setNominalCurrent", null);
    }

    private controlChangeValue(control: any) {
        this.setControlsVisibility();

        this.$emit("changeValue");
    }

    private onImageLoad(event) {
        this.container = <HTMLElement>this.$refs.container;
    }

    private controlLoadComplete(control: any) {
        this.controlLoadCount++;

        if ((control.type === "Button" || control.type === "Display") && this.controlLoadCount == this.tripUnitData.controls.filter(x => x.type === "Button" || x.type === "Display").length) {
            this.isContainerLoading = false;
            this.setControlsVisibility();
        } else if (control.type === "Button" || control.type === "Display") {
            return;
        }

        if (this.controlLoadCount == this.controlCount) {
            this.isContainerLoading = false;
            this.setControlsVisibility();
        }
    }

    private setControlType(control: any) {
        switch (control.type) {
            case "DIPSwitchList": return "app-dipswitchlist";
            case "Display": return "app-display";
            case "Button": return "app-button";
            default: return null;
        }
    }

    private showFullscreen() {
        let message = document.getElementById("Message");
        message.setAttribute("data-show", "true");

        setTimeout(function () {
            message.setAttribute("data-show", "false");
        }, 3000);
    }

    private setShowEdit() {
        this.showEdit = !this.showEdit;
    }

    private setControlsVisibility() {
        if (this.tripUnitData.isSISelector) {
            let controlSI = this.tripUnitData.controls.filter(x => x.name === 'si')[0];
            let controlI = this.tripUnitData.controls.filter(x => x.name === 'i3')[0];
            let controlS = this.tripUnitData.controls.filter(x => x.name === 'i2')[0];

            // Set S or I protection visibility
            if (controlSI.value === "I") {
                controlS.value = 0;
                controlS.isVisible = false;

                controlI.isVisible = true;
            } else if (controlSI.value === "S") {
                controlI.value = 0;
                controlI.isVisible = false;

                controlS.isVisible = true;
            }
        }
    }

    private closePrint() {
        this.formData = {};
        this.$emit("closePrint");
    }

    private saveNotes() {
        this.$emit("closePrint");

        setTimeout(function () {
            window.print();
        }, 500);
    }
};