import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import HtmlHelper from "@Scripts/Infrastructure/HtmlHelper";

@Component({ name: "InfoArea" })
export default class InfoArea extends Vue {
    private configuration: boolean[] = [];
    private controlStyle: string = null;

    @Prop() readonly control: any;
    @Prop() readonly container: HTMLElement;

    @Watch("container") onContainerChanged(value: any) {
        let control = <HTMLElement>this.$refs.control;
        let vm = this;

        HtmlHelper.onAllImagesLoaded(control, x => {
            let controlRect = <DOMRect>control.getBoundingClientRect();

            let positions = HtmlHelper.getPercentagePositions(vm.container, vm.control.position.x, vm.control.position.y, vm.control.size.width, vm.control.size.height);

            vm.controlStyle = "top:" + positions.y + "%; left:" + positions.x + "%; width:" + positions.width + "%; height:" + positions.height + "%;";

            this.$emit("loadComplete", this.control);
        });
    }

    private onClickInfoArea() {
        this.$emit("clickInfoArea", this.control);
    }
};