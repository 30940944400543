import Vue from "vue";
import Component from "vue-class-component";

@Component({ name: "Footer" })
export default class Footer extends Vue {
    private showFooter: boolean = false;

    get appResources() {
        return this.$store.getters["appResourceStore/appResources"];
    }

    private setShowFooter() {
        this.showFooter = !this.showFooter;
    }
};