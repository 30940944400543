import Vue from "vue";
import App from "@Scripts/Components/App/App.vue";
import Header from "@Scripts/Components/Header/Header.vue";
import CultureSelector from "@Scripts/Components/CultureSelector/CultureSelector.vue";
import Footer from "@Scripts/Components/Footer/Footer.vue";
import ToastHub from "@Scripts/Components/TripUnitDetail/Toast/ToastHub.vue";
import SettingConfirm from "@Scripts/Components/TripUnitDetail/Controls/SettingConfirm/SettingConfirm.vue";
import TripUnitMenu from "@Scripts/Components/TripUnitDetail/Menu/TripUnitMenu.vue";
import NotFound from "@Scripts/Components/NotFound/NotFound.vue";
import TripUnitList from "@Scripts/Components/TripUnitList/TripUnitList.vue";
import TripUnitSearchBox from "@Scripts/Components/TripUnitList/Search/TripUnitSearchBox.vue";
import TripUnitDetail from "@Scripts/Components/TripUnitDetail/TripUnitDetail.vue";
import DIPSwitchList from "@Scripts/Components/TripUnitDetail/Controls/DIPSwitchList/DIPSwitchList.vue";
import DIPSwitch from "@Scripts/Components/TripUnitDetail/Controls/DIPSwitch/DIPSwitch.vue";
import Notification from "@Scripts/Components/TripUnitDetail/Controls/Notification/Notification.vue";
import Led from "@Scripts/Components/TripUnitDetail/Controls/Led/Led.vue";
import InfoArea from "@Scripts/Components/TripUnitDetail/Controls/InfoArea/InfoArea.vue";
import TripUnitSummary from "@Scripts/Components/TripUnitDetail/Summary/TripUnitSummary.vue";
import Chart from "@Scripts/Components/TripUnitDetail/Chart/Chart.vue";
import Display from "@Scripts/Components/TripUnitDetail/Controls/Display/Display.vue";
import Button from "@Scripts/Components/TripUnitDetail/Controls/Button/Button.vue";
import PinCode from "@Scripts/Components/TripUnitDetail/Controls/PinCode/PinCode.vue";
import ButtonList from "@Scripts/Components/TripUnitDetail/Controls/Display/ScreenTypes/ButtonList.vue";
import SettingList from "@Scripts/Components/TripUnitDetail/Controls/Display/ScreenTypes/SettingList.vue";
import ListSelector from "@Scripts/Components/TripUnitDetail/Controls/Display/ScreenTypes/ListSelector.vue";
import NumericSelector from "@Scripts/Components/TripUnitDetail/Controls/Display/ScreenTypes/NumericSelector.vue";
import ImageList from "@Scripts/Components/TripUnitDetail/Controls/Display/ScreenTypes/ImageList.vue";
import ItemList from "@Scripts/Components/TripUnitDetail/Controls/Display/ScreenTypes/ItemList.vue";
import ItemTable from "@Scripts/Components/TripUnitDetail/Controls/Display/ScreenTypes/ItemTable.vue";

import ExpandingMenu from "@Scripts/Directives/ExpandingMenu/ExpandingMenu";
import ClickOutOverview from "@Scripts/Directives/ClickOutOverview/ClickOutOverview";
import ClickOutImageList from "@Scripts/Directives/ClickOutImageList/ClickOutImageList";

export default class AppConfig {
    public static AddComponents() {
        Vue.component("app", App);
        Vue.component("app-header", Header);
        Vue.component("app-cultureselector", CultureSelector);
        Vue.component("app-footer", Footer);
        Vue.component("app-tripunitmenu", TripUnitMenu);
        Vue.component("app-tripunitsummary", TripUnitSummary);
        Vue.component("app-chart", Chart);
        Vue.component("app-settingconfirm", SettingConfirm)
        Vue.component("app-toasthub", ToastHub)
        Vue.component("app-notfound", NotFound);
        Vue.component("app-tripunitlist", TripUnitList);
        Vue.component("app-tripunitsearchbox", TripUnitSearchBox);
        Vue.component("app-tripunitdetail", TripUnitDetail);
        Vue.component("app-dipswitchlist", DIPSwitchList);
        Vue.component("app-dipswitch", DIPSwitch);
        Vue.component("app-notification", Notification);
        Vue.component("app-led", Led);
        Vue.component("app-infoarea", InfoArea);
        Vue.component("app-display", Display);
        Vue.component("app-button", Button);
        Vue.component("app-pincode", PinCode);
        Vue.component("app-screen-button-list", ButtonList);
        Vue.component("app-screen-setting-list", SettingList);
        Vue.component("app-screen-list-selector", ListSelector);
        Vue.component("app-screen-numeric-selector", NumericSelector);
        Vue.component("app-screen-image-list", ImageList);
        Vue.component("app-screen-item-list", ItemList);
        Vue.component("app-screen-item-table", ItemTable);
    }

    public static AddDirectives() {
        Vue.directive("expanding-menu", ExpandingMenu);
        Vue.directive("clickoutoverview-menu", ClickOutOverview);
        Vue.directive("clickoutimagelist", ClickOutImageList);
    }
}