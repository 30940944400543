import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import ResourceHelper from "@Scripts/Infrastructure/ResourceHelper";
import MediaHelper from "@Scripts/Infrastructure/MediaHelper";

@Component({ name: "TripUnitAlarms" })
export default class TripUnitAlarms extends Vue {
    private summaryData: any = null;
    private showSummaryData: boolean = false;
    private resourceHelper: any = ResourceHelper;
    private mediaHelper: any = MediaHelper;
    private container: HTMLElement = null;
    private isContainerLoading: boolean = true;
    private controlCount: number = 0;
    private controlLoadCount: number = 0;
    private alarmItems: any[] = [];
    private alarmName: string = null;
    private alarm: any = { title: "", description: "" };
    private showEdit: boolean = false;
    private isSmallDevice: boolean = false;

    @Prop() readonly tripUnitData: any;
    @Prop() readonly isSummaryUpdate: any;

    @Watch("isSummaryUpdate") onSummaryUpdateChanged(value: boolean) {
        this.setDefaultAlarms();
    }

    @Watch("alarm") onAlarmChanged(value: any) {
        if (!value) {
            this.alarm = { title: "", description: "" };
        }
    }

    @Watch("summaryData") onSummaryDataChanged(value: any) {
        if (this.summaryData.LedL != "Off" || this.summaryData.LedS != "Off" || this.summaryData.LedI != "Off" || this.summaryData.LedG != "Off") {
            this.showSummaryData = true;
        } else {
            this.showSummaryData = false;
        }
    }

    get culture() {
        return this.$store.getters["appResourceStore/culture"];
    }

    get appResources() {
        return this.$store.getters["appResourceStore/appResources"];
    }

    private created() {
        this.controlCount = this.tripUnitData.controls.filter(x => x.type === "Led" || x.type === "Notification").length;

        this.setAlarmItems();
        this.setSummaryData();

        this.isSmallDevice = this.tripUnitData.isSmallDevice ? true : false;
    }

    private destroyed() {
        this.turnOffAllControls();
    }

    private onImageLoad(e) {
        this.container = <HTMLElement>this.$refs.container;
    }

    private controlLoadComplete() {
        this.controlLoadCount++;

        if (this.controlLoadCount == this.controlCount) {
            this.isContainerLoading = false;
            this.controlChangeValue();
        }
    }

    private controlChangeValue() {
        this.alarm = null;
        this.alarmName = null;
        let controls = this.tripUnitData.controls.filter(x => x.type === "Led" || x.type === "Notification");

        for (const alarm of this.tripUnitData.alarms) {
            // Add null (Off) values to configuration
            let fullConfiguration = JSON.parse(JSON.stringify(alarm.configuration));
            for (const control of controls) {
                if (fullConfiguration.filter(x => x.name === control.name).length === 0) {
                    fullConfiguration.push({ name: control.name, value: null });
                }

                switch (control.type) {
                    case "Led":
                        if (control.value && control.color && control.configuration) {
                            this.summaryData[control.name].value = control.value;
                            this.summaryData[control.name].color = control.color;
                            this.summaryData[control.name].configuration = control.configuration;
                        } else {
                            control.configuration = null;
                            control.value = null;
                            control.color = null;
                            this.summaryData[control.name] = control;
                        }
                        break;
                    case "Notification":
                        if (control.value && control.configuration) {
                            control.styleIcon = control.configurations.find(x => x.configuration == control.configuration).status;
                        } else {
                            control.configuration = null;
                            control.value = null;
                            control.styleIcon = null;
                        }
                        break;
                }
            }

            let successValues = [];

            for (const configurationItem of fullConfiguration) {
                if (configurationItem.configuration) {
                    successValues.push(controls.filter(x => x.name === configurationItem.name && x.configuration === configurationItem.configuration).length !== 0);
                } else {
                    successValues.push(controls.filter(x => x.name === configurationItem.name && !x.configuration).length !== 0);
                }
            }

            if (!successValues.includes(false)) {
                this.alarm = alarm;
                this.alarmName = alarm.name;
            }
        }

        this.syncBlinkingLeds();
    }

    private setAlarmConfiguration() {
        this.alarm = null;

        let alarms = this.tripUnitData.alarms.filter(x => x.name === this.alarmName);
        let dataAlarm = document.getElementById("Tripunit");
        dataAlarm.setAttribute("data-info", "false");

        this.turnOffAllControls();

        if (alarms.length !== 0) {
            this.alarm = alarms[0];
            dataAlarm.setAttribute("data-info", "true");

            for (const configurationItem of this.alarm.configuration) {
                let controls = this.tripUnitData.controls.filter(x => x.name === configurationItem.name);
                if (controls.length !== 0) {
                    let config = controls[0].configurations.filter(x => x.configuration === configurationItem.configuration)[0];
                    controls[0].value = config.value;
                    controls[0].color = config.color;
                    controls[0].configuration = config.configuration;
                    if (controls[0].type === "Notification") {
                        let style = controls[0].configurations.find(x => x.configuration == controls[0].configuration).status;
                        controls[0].style = style ? style : controls[0].style;
                    }
                    this.summaryData[controls[0].name] = config;
                }
            }
        } else if (!this.alarmName) {
            let controls = this.tripUnitData.controls.filter(x => x.type === "Led" || x.type === "Notification");
            for (const control of controls) {
                control.configuration = null;
                control.value = null;
                control.color = null;
                this.summaryData[control.name] = control;
            }
        }

        this.syncBlinkingLeds();
    }

    private turnOffAllControls() {
        let ledControls = this.tripUnitData.controls.filter(x => x.type === "Led");
        for (const ledControl of ledControls) {
            ledControl.value = null;
        }

        let notificationControls = this.tripUnitData.controls.filter(x => x.type === "Notification");
        for (const notificationControl of notificationControls) {
            notificationControl.configuration = null;
            notificationControl.value = null;
            notificationControl.styleIcon = null;
        }
    }

    private syncBlinkingLeds() {
        this.setSummaryData();
        this.$nextTick(function () {
            let blinkingLeds = this.container.querySelectorAll("[data-mode='2hz'], [data-mode='0-5hz'], [data-mode='2b05s2sp'], [data-mode='3b05s3sp'], [data-mode='4b05s4sp']");

            for (const blinkingLed of blinkingLeds) {
                blinkingLed.setAttribute("data-mode", blinkingLed.getAttribute("data-mode") + "-reset");
            }

            setTimeout(function () {
                for (const blinkingLed of blinkingLeds) {
                    blinkingLed.setAttribute("data-mode", blinkingLed.getAttribute("data-mode").replace("-reset", ""));
                }
            }, 500)
        });
    }

    private setAlarmItems() {
        this.alarmItems = this.tripUnitData.alarms.map(x => { return { title: x.name, value: x.name } });
        this.alarmItems.unshift({ title: [{ "culture": "en-GB", "value": "..." }], value: null });
    }

    private setControlType(control: any) {
        switch (control.type) {
            case "Led": return "app-led";
            case "Notification": return "app-notification";
            default: return null;
        }
    }

    private setDefaultAlarms() {
        this.alarmName = null;
        this.setAlarmConfiguration();
        this.setSummaryData();
    }

    private setSummaryData() {
        this.summaryData = {
            isLedL: this.tripUnitData.protections.filter(x => x === "L").length !== 0,
            isLedS: this.tripUnitData.protections.filter(x => x === "S").length !== 0,
            isLedI: this.tripUnitData.protections.filter(x => x === "I").length !== 0,
            isLedG: this.tripUnitData.protections.filter(x => x === "G").length !== 0,
            isLedU: this.tripUnitData.protections.filter(x => x === "U").length !== 0
        };

        if (this.summaryData.isLedL) {
            this.summaryData.LedL = this.getLedValue("LedL");
        }
        if (this.summaryData.isLedS) {
            this.summaryData.LedS = this.getLedValue("LedS");
        }
        if (this.summaryData.isLedI) {
            this.summaryData.LedI = this.getLedValue("LedI");
        }
        if (this.summaryData.isLedG) {
            this.summaryData.LedG = this.getLedValue("LedG");
        }
        if (this.summaryData.isLedU) {
            this.summaryData.LedU = this.getLedValue("LedU");
        }
        this.summaryData.Power = this.getLedValue("Power");
        this.summaryData.Warning = this.getLedValue("Warning");
        this.summaryData.Prealarm = this.getLedValue("Prealarm");
        this.summaryData.Alarm = this.getLedValue("Alarm");
    }

    private getLedValue(LedName: string): any {
        let ledConfiguration: any = {
            configuration: null,
            value: null,
            color: null
        }

        let controls = this.tripUnitData.controls.filter(x => x.name === LedName);
        if (controls.length !== 0) {
            ledConfiguration.value = controls[0].value;
            ledConfiguration.color = controls[0].color;
            ledConfiguration.configuration = controls[0].configuration;
            if (!controls[0].value) {
                ledConfiguration.configuration = "Off";
            }
        }

        return ledConfiguration;
    }

    private showFullscreen() {
        let message = document.getElementById("Message");
        message.setAttribute("data-show", "true");

        setTimeout(function () {
            message.setAttribute("data-show", "false");
        }, 3000);
    }

    private setShowEdit() {
        this.showEdit = !this.showEdit;
    }
};