import Vue from "vue";
import VueRouter from "vue-router";
import NotFound from "@Scripts/Components/NotFound/NotFound.vue";
import TripUnitList from "@Scripts/Components/TripUnitList/TripUnitList.vue";
import TripUnitDetail from "@Scripts/Components/TripUnitDetail/TripUnitDetail.vue";
import TripUnitOverview from "@Scripts/Components/TripUnitDetail/Modules/TripUnitOverview.vue";
import TripUnitSettings from "@Scripts/Components/TripUnitDetail/Modules/TripUnitSettings.vue";
import TripUnitAlarms from "@Scripts/Components/TripUnitDetail/Modules/TripUnitAlarms.vue";
import TripUnitLibrary from "@Scripts/Components/TripUnitDetail/Modules/TripUnitLibrary.vue";
import TripUnitScenarios from "@Scripts/Components/TripUnitDetail/Modules/TripUnitScenarios.vue";

Vue.use(VueRouter);

export const router = new VueRouter({
    mode: "history",
    routes: [
        { name: "Intro", path: "/app", redirect: { name: "TripUnitList" } },
        { name: "TripUnitList", path: "/app/tripunit", component: TripUnitList, props: true },
        {
            name: "TripUnitDetail", path: "/app/tripunit/:name", component: TripUnitDetail, redirect: { name: "Overview" }, props: true,
            children: [
                { name: "Overview", path: "/app/tripunit/:name/overview", component: TripUnitOverview, props: true, meta: { sectionName: "Overview" } },
                { name: "Settings", path: "/app/tripunit/:name/settings", component: TripUnitSettings, props: true, meta: { sectionName: "Settings" } },
                { name: "Alarms", path: "/app/tripunit/:name/alarms", component: TripUnitAlarms, props: true, meta: { sectionName: "Alarms" } },
                { name: "Library", path: "/app/tripunit/:name/library", component: TripUnitLibrary, props: true, meta: { sectionName: "Library" } },
                { name: "Scenarios", path: "/app/tripunit/:name/scenarios", component: TripUnitScenarios, props: true, meta: { sectionName: "Scenarios" } }
            ]
        },
        { name: "NotFound", path: "*", component: NotFound }
    ]
});