import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import PaginationHelper from "@Scripts/Infrastructure/PaginationHelper";
import RouteParamHelper from "@Scripts/Infrastructure/RouteParamHelper";

@Component({ name: "SettingList" })
export default class SettingList extends Vue {
    private settingList: any[] = [];
    private settingsInPage: any[] = [];
    private page: number = 1;
    private visibleElements: number = 5;
    private barStyle: string = null;
    private unsubscribe: any = null;
    private buttonIndexSelected: number = 0;

    get settings() {
        return this.$store.getters["deviceSettingStore/settings"];
    }

    get cacheSettings() {
        return this.$store.getters["deviceSettingStore/cacheSettings"];
    }

    get pin() {
        return this.$store.getters["deviceSettingStore/pin"];
    }

    get isSettingConfirmVisible() {
        return this.$store.getters["deviceSettingStore/isSettingConfirmVisible"];
    }

    @Prop() readonly control: any;
    @Prop() readonly screen: any;
    @Prop() readonly isSmallDevice: boolean;

    @Watch("$route.query.displayNav") onRouteQueryDisplayNavChanged(value: string) {
        this.checkSettingList();
        this.page = 1;
        this.buttonIndexSelected = 0;
        this.$store.commit("deviceSettingStore/setLastSettingPath", <string>this.$route.query.displayNav);
        this.settingsInPage = PaginationHelper.pagination(this.page, this.settingList, this.visibleElements);
        this.barStyle = PaginationHelper.setBarStyle(Math.ceil(this.settingList.length / this.visibleElements), this.page);
    }

    private created() {
        this.visibleElements = this.isSmallDevice ? 2 : 5;
        this.checkSettingList();
        this.settingsInPage = PaginationHelper.pagination(this.page, this.settingList, this.visibleElements);
        this.barStyle = PaginationHelper.setBarStyle(Math.ceil(this.settingList.length / this.visibleElements), this.page);

        this.unsubscribe = (<any>this.$store).subscribeAction((action, state) => {
            if ((this.pin.isVisible && !this.pin.isCheked) || this.isSettingConfirmVisible) {
                return;
            }

            if (action.type === "deviceNavigationStore/onPressButtonEnter") {
                this.setAction(this.screen.settings.items[this.buttonIndexSelected]);
            } else if (action.type === "deviceNavigationStore/onPressButtonDown") {
                if (this.buttonIndexSelected < (this.screen.settings.items.length - 1)) {
                    this.buttonIndexSelected++;
                }

                if (this.buttonIndexSelected % this.visibleElements === 0) {
                    this.slideDown();
                }
            } else if (action.type === "deviceNavigationStore/onPressButtonUp") {
                if (this.buttonIndexSelected >= 1) {
                    this.buttonIndexSelected--;
                }

                if (this.buttonIndexSelected % this.visibleElements !== 0) {
                    this.slideUp();
                }
            }
        });
    }

    private beforeDestroy() {
        this.unsubscribe();
    }

    private checkSettingList() {
        this.settingList = [];
        let totalIndex = 0;
        for (let i = 0; i < this.screen.settings.items.length; i++) {
            if (this.screen.settings.items[i].visibleCondition) {
                let isVisibleArray: boolean[] = [];

                for (let j = 0; j < this.screen.settings.items[i].visibleCondition.length; j++) {
                    isVisibleArray.push(this.checkVisibleCondition(this.screen.settings.items[i].visibleCondition[j]));
                }

                if (isVisibleArray.every(x => x === false)) {
                    continue;
                }
            }

            this.screen.settings.items[i].index = totalIndex;
            this.settingList.push(this.screen.settings.items[i]);
            totalIndex++;
        }

        this.checkEmptyItem();
    }

    private checkEmptyItem() {
        let totElement = this.visibleElements * Math.ceil(this.settingList.length / this.visibleElements);
        let emptyObjsNumber = totElement - this.settingList.length;

        for (let index = 0; index < emptyObjsNumber; index++) {
            this.settingList.push({});
        }
    }

    private checkVisibleCondition(visibleCondition: any): boolean {
        if (this.cacheSettings.some(x => x.name === visibleCondition.settingName)) {
            if (this.cacheSettings.find(x => x.name === visibleCondition.settingName).value !== visibleCondition.value) {
                return false;
            }
        } else if (this.settings.some(x => x.name === visibleCondition.settingName)) {
            if (this.settings.find(x => x.name === visibleCondition.settingName).value !== visibleCondition.value) {
                return false;
            }
        }
        return true;
    }

    private getSettingValue(settingName: string) {
        let unitsOfMeasurement = this.checkUnitOfMeasurement(settingName);

        if (this.cacheSettings.some(x => x.name === settingName)) {
            return this.cacheSettings.find(x => x.name === settingName).value + unitsOfMeasurement;
        } else {
            return this.settings.find(x => x.name === settingName)?.value + unitsOfMeasurement;
        }
    }

    private checkUnitOfMeasurement(settingName: string): string {
        let settings = this.screen.settings.items.filter(x => x.settingName === settingName);
        if (settings.length === 0) {
            return "";
        }

        let settingPath = settings[0]?.actionValue;

        let unitsOfMeasurement = this.control.screens.find(x => x.path === settingPath)?.settings?.unitsOfMeasurement;
        if (unitsOfMeasurement) {
            return " " + unitsOfMeasurement;
        }

        return "";
    }

    private setAction(setting: any) {
        if (setting.actionValue) {
            this.$router.push({ path: window.location.pathname, query: { displayNav: setting.actionValue } }).catch(() => { });
        }
    }

    private slideUp() {
        if (this.page > 1) {
            this.page--;
            this.settingsInPage = PaginationHelper.pagination(this.page, this.settingList, this.visibleElements);
            this.barStyle = PaginationHelper.setBarStyle(Math.ceil(this.settingList.length / this.visibleElements), this.page);
        }
    }

    private slideDown() {
        if (this.page !== Math.ceil(this.settingList.length / this.visibleElements)) {
            this.page++;
            this.settingsInPage = PaginationHelper.pagination(this.page, this.settingList, this.visibleElements);
            this.barStyle = PaginationHelper.setBarStyle(Math.ceil(this.settingList.length / this.visibleElements), this.page);
        }
    }

    private goBack() {
        this.$router.push({ path: window.location.pathname, query: { displayNav: RouteParamHelper.goBack(<string>this.$route.query.displayNav) } }).catch(() => { });
    }
}