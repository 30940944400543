const state = {
    header: true
};

const getters = {
    isHeader: state => state.header
};

const mutations = {
    setIsHeader: (state, data) => state.header = data
};

export default {
    namespaced: true,
    state,
    getters,
    mutations
};