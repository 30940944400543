import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import ResourceHelper from "@Scripts/Infrastructure/ResourceHelper";
import MediaHelper from "@Scripts/Infrastructure/MediaHelper";

@Component({ name: "TripUnitOverview" })
export default class TripUnitOverview extends Vue {
    private resourceHelper: any = ResourceHelper;
    private mediaHelper: any = MediaHelper;
    private container: HTMLElement = null;
    private isContainerLoading: boolean = true;
    private controlCount: number = 0;
    private controlLoadCount: number = 0;
    private sensitiveArea: any = { title: null, description: null, name: null };
    private controlStyle: string = null;

    @Prop() readonly tripUnitData: any;

    get culture() {
        return this.$store.getters["appResourceStore/culture"];
    }

    get appResources() {
        return this.$store.getters["appResourceStore/appResources"];
    }

    private created() {
        this.controlCount = this.tripUnitData.controls.filter(x => x.type === "InfoArea").length;
    }

    private onImageLoad(event) {
        this.container = <HTMLElement>this.$refs.container;
    }

    private controlLoadComplete() {
        this.controlLoadCount++;

        if (this.controlLoadCount == this.controlCount) {
            this.isContainerLoading = false;
        }
    }

    private setControlType(control: any) {
        switch (control.type) {
            case "InfoArea": return "app-infoarea";
            default: return null;
        }
    }

    private onClickInfoArea(control: any) {
        this.sensitiveArea = { title: control.title, description: control.description, name: control.name };
    }

    private closeSensitiveArea() {
        this.sensitiveArea = null;
    }

    private showFullscreen() {
        let message = document.getElementById("Message");
        message.setAttribute("data-show", "true");

        setTimeout(function () {
            message.setAttribute("data-show", "false");
        }, 3000);
    }
};