import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import MediaHelper from "@Scripts/Infrastructure/MediaHelper";

@Component({ name: "DIPSwitch" })
export default class DIPSwitch extends Vue {
    private mediaHelper: any = MediaHelper;
    private state: any = null;

    @Prop() readonly index: number;
    @Prop() readonly configuration: number;
    @Prop() readonly control: any;
    @Prop() readonly gap: any;

    @Watch("configuration") onConfigurationChanged(value: boolean[], oldValue: boolean[]) {
        this.state.isActive = this.getIsActiveFromConfiguration();
    }

    private created() {
        this.state = { isActive: this.getIsActiveFromConfiguration(), index: this.index };
    }

    private setIsActive() {
        let url = this.$route.path;
        if (url.includes("/settings")) {
            this.state.isActive = !this.state.isActive;

            this.$emit("changeValue", this.state);
        }
    }

    private getIsActiveFromConfiguration(): boolean {
        return this.configuration[this.index];
    }
};