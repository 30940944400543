import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import HtmlHelper from "@Scripts/Infrastructure/HtmlHelper";
import MediaHelper from "@Scripts/Infrastructure/MediaHelper";

@Component({ name: "Led" })
export default class Led extends Vue {
    private controlStyle: string = null;
    private mediaHelper: any = MediaHelper;

    @Prop() readonly control: any;
    @Prop() readonly isInteractive: boolean;
    @Prop() readonly container: HTMLElement;

    @Watch("container") onContainerChanged(value: any) {
        let control = <HTMLElement>this.$refs.control;
        let vm = this;

        HtmlHelper.onAllImagesLoaded(control, x => {
            let controlRect = <DOMRect>control.getBoundingClientRect();

            let positions = HtmlHelper.getPercentagePositions(vm.container, vm.control.position.x, vm.control.position.y, controlRect.width);

            vm.controlStyle = "top:" + positions.y + "%; left:" + positions.x + "%; width:" + positions.width + "%;";

            this.$emit("loadComplete", this.control);
        });
    }

    private setValue(config: any) {
        this.control.value = config.value;
        this.control.color = config.color;
        this.control.configuration = config.configuration;
        this.closeSelector();
        this.$emit("changeValue", this.control);
    }

    private closeSelector() {
        let selectors = document.getElementsByClassName("led-selector");
        for (const selector of selectors) {
            selector.classList.remove("show");
        }
    }
};