import { DirectiveOptions } from "vue";

const directive: DirectiveOptions = {
    inserted(el, node, vnode) {
        hideOnClickOutside(el);

        function hideOnClickOutside(element) {
            const outsideClickListener = event => {
                if (event.target.dataset.type != vnode.context['sensitiveArea'].name) {
                    if (isVisible(element) && (event.target.className === "wrap-tripunit" || event.target.className === "background")) {
                        vnode.context['sensitiveArea'] = { title: null, description: null, name: null };
                    }
                } else if (vnode.context['sensitiveArea'].name == null) {
                    if (event.target.className === "wrap-tripunit" || event.target.className === "background") {
                        let selectors = document.getElementsByClassName("sensitive-area");
                        for (const selector of selectors) {
                            selector.classList.add("blink");
                        }
                        setTimeout(function () {
                            for (const selector of selectors) {
                                selector.classList.remove("blink");
                            }
                        }, 1000)
                    }
                }
            }
            document.addEventListener("click", outsideClickListener);
        }

        const isVisible = elem => !!elem && !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);
    }
};

export default directive;