import { render, staticRenderFns } from "./TripUnitSearchBox.vue?vue&type=template&id=24a08a8b"
import script from "./TripUnitSearchBox?vue&type=script&lang=ts&external"
export * from "./TripUnitSearchBox?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports