const state = {
    returnParameters: null
};

const getters = {
    returnParameters: state => state.returnParameters
};

const mutations = {
    setReturnParameters: (state, data) => state.returnParameters = data
};

export default {
    namespaced: true,
    state,
    getters,
    mutations
};