import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import RouteParamHelper from "@Scripts/Infrastructure/RouteParamHelper";
import PaginationHelper from "@Scripts/Infrastructure/PaginationHelper";

@Component({ name: "ItemTable" })
export default class ItemTable extends Vue {
    private list: any[] = [];
    private page: number = 1;
    private visibleElements: number = 9;
    private barStyle: string = null;
    private buttonIndexSelected: number = 0;
    private unsubscribe: any = null;

    get pin() {
        return this.$store.getters["deviceSettingStore/pin"];
    }

    get isSettingConfirmVisible() {
        return this.$store.getters["deviceSettingStore/isSettingConfirmVisible"];
    }

    @Prop() readonly screen: any;
    @Prop() readonly isSmallDevice: boolean;

    private created() {
        this.visibleElements = this.isSmallDevice ? 1 : 9;
        this.list = PaginationHelper.pagination(this.page, this.screen.settings.items, this.visibleElements);
        this.barStyle = PaginationHelper.setBarStyle(Math.ceil(this.screen.settings.items.length / this.visibleElements), this.page);

        this.unsubscribe = (<any>this.$store).subscribeAction((action, state) => {
            if ((this.pin.isVisible && !this.pin.isCheked) || this.isSettingConfirmVisible) {
                return;
            }

            if (action.type === "deviceNavigationStore/onPressButtonDown") {
                if (this.buttonIndexSelected < (this.screen.settings.items.length - 1)) {
                    this.buttonIndexSelected++;
                }

                if (this.buttonIndexSelected % this.visibleElements === 0) {
                    this.slideDown();
                }
            } else if (action.type === "deviceNavigationStore/onPressButtonUp") {
                if (this.buttonIndexSelected >= 1) {
                    this.buttonIndexSelected--;

                    this.slideUp();
                }
            }
        });
    }

    private beforeDestroy() {
        this.unsubscribe();
    }

    private slideUp() {
        if (this.page > 1) {
            this.page--;
            this.list = PaginationHelper.pagination(this.page, this.screen.settings.items, this.visibleElements);
            this.barStyle = PaginationHelper.setBarStyle(Math.ceil(this.screen.settings.items.length / this.visibleElements), this.page);
        }
    }

    private slideDown() {
        if (this.page !== Math.ceil(this.screen.settings.items.length / this.visibleElements)) {
            this.page++;
            this.list = PaginationHelper.pagination(this.page, this.screen.settings.items, this.visibleElements);
            this.barStyle = PaginationHelper.setBarStyle(Math.ceil(this.screen.settings.items.length / this.visibleElements), this.page);
        }
    }

    private goBack() {
        this.$router.push({ path: window.location.pathname, query: { displayNav: RouteParamHelper.goBack(<string>this.$route.query.displayNav) } }).catch(() => { });
    }
}