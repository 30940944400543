import Axios from "axios";

const state = {
    culture: null,
    cultures: [],
    appResources: null
};

const getters = {
    culture: state => state.culture,
    cultures: state => state.cultures,
    appResources: state => state.appResources
};

const mutations = {
    setCulture: (state, data) => state.culture = data,
    setCultures: (state, data) => state.cultures = data,
    setAppResources: (state, data) => state.appResources = data
};

const actions = {
    getAppResources: async (context, data) => {
        let response = await Axios.get("/data/resources/" + context.getters["culture"].toLowerCase() + ".json");

        context.commit("setAppResources", Object.freeze(response.data));

        return response;
    },
    getCultures: async (context, data) => {
        let response = await Axios.get("/data/cultures/cultures.json");

        context.commit("setCultures", Object.freeze(response.data));

        return response;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};