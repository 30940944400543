const state = {};

const getters = {};

const mutations = {};

const actions = {
    onPressButtonEnter: (context, data) => { },
    onPressButtonDown: (context, data) => { },
    onPressButtonUp: (context, data) => { }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};