import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import PaginationHelper from "@Scripts/Infrastructure/PaginationHelper";
import RouteParamHelper from "@Scripts/Infrastructure/RouteParamHelper";

@Component({ name: "ListSelector" })
export default class ListSelector extends Vue {
    private settingName: string = null;
    private settingValue: string = null;
    private settingValues: any[] = [];
    private values: any[] = [];
    private page: number = 1;
    private visibleElements: number = 4;
    private barStyle: string = null;
    private buttonIndexSelected: number = 0;
    private unsubscribe: any = null;

    get appResources() {
        return this.$store.getters["appResourceStore/appResources"];
    }

    get settings() {
        return this.$store.getters["deviceSettingStore/settings"];
    }

    get cacheSettings() {
        return this.$store.getters["deviceSettingStore/cacheSettings"];
    }

    get pin() {
        return this.$store.getters["deviceSettingStore/pin"];
    }

    get isSettingConfirmVisible() {
        return this.$store.getters["deviceSettingStore/isSettingConfirmVisible"];
    }

    @Prop() readonly control: any;
    @Prop() readonly screen: any;
    @Prop() readonly isSmallDevice: boolean;

    private created() {
        for (let index = 0; index < this.screen.settings.values.length; index++) {
            const element = this.screen.settings.values[index];
            this.settingValues.push({ title: element, index: index })
        }

        this.visibleElements = this.isSmallDevice ? 2 : 4;
        this.settingName = this.screen.settings.settingName;
        this.settingValue = this.getSettingValue();
        this.focusOnDefaultValue();
        this.$store.commit("deviceSettingStore/setLastSettingPath", <string>this.$route.query.displayNav);

        this.checkEmptyItem();

        this.unsubscribe = (<any>this.$store).subscribeAction((action, state) => {
            if ((this.pin.isVisible && !this.pin.isCheked) || this.isSettingConfirmVisible) {
                return;
            }

            if (action.type === "deviceNavigationStore/onPressButtonEnter") {
                this.setSettingValue(this.settingValues[this.buttonIndexSelected].title);
            } else if (action.type === "deviceNavigationStore/onPressButtonDown") {
                if (this.buttonIndexSelected < (this.settingValues.length - 1)) {
                    this.buttonIndexSelected++;
                }

                if (this.buttonIndexSelected % this.visibleElements === 0) {
                    this.slideDown();
                }
            } else if (action.type === "deviceNavigationStore/onPressButtonUp") {
                if (this.buttonIndexSelected >= 1) {
                    this.buttonIndexSelected--;
                }

                if (this.buttonIndexSelected % this.visibleElements !== 0) {
                    this.slideUp();
                }
            }
        });
    }

    private beforeDestroy() {
        this.unsubscribe();
    }

    private focusOnDefaultValue() {
        this.buttonIndexSelected = this.settingValues.findIndex(x => x.title === this.settingValue);
        this.page = Math.trunc(this.buttonIndexSelected / this.visibleElements) + 1;

        this.values = PaginationHelper.pagination(this.page, this.settingValues, this.visibleElements);
        this.barStyle = PaginationHelper.setBarStyle(Math.ceil(this.settingValues.length / this.visibleElements), this.page);
    }

    private checkEmptyItem() {
        let totElement = this.visibleElements * Math.ceil(this.values.length / this.visibleElements);
        let emptyObjsNumber = totElement - this.values.length;

        for (let index = 0; index < emptyObjsNumber; index++) {
            this.values.push({ title: null, index: index });
        }
    }

    private getSettingValue(): string {
        if (this.cacheSettings.some(x => x.name === this.settingName)) {
            return this.cacheSettings.find(x => x.name === this.settingName).value;
        } else {
            return this.settings.find(x => x.name === this.settingName)?.value;
        }
    }

    private setSettingValue(value: string) {
        if (!value) {
            return;
        }

        let oldValue = this.settings.find(x => x.name === this.settingName).value;
        if (oldValue !== value) {
            let settingPath = this.screen.path.split(":").slice(0, 4).join(":");
            let settingScreen = this.control.screens.find(x => x.path === settingPath);

            this.$store.dispatch("deviceSettingStore/addCacheSettings", { name: this.settingName, value: value, settingName: settingScreen.title });
        } else {
            this.$store.dispatch("deviceSettingStore/removeCacheSettings", this.settingName);
        }
        this.goBack();
    }

    private slideUp() {
        if (this.page > 1) {
            this.page--;
            this.values = PaginationHelper.pagination(this.page, this.settingValues, this.visibleElements);
            this.barStyle = PaginationHelper.setBarStyle(Math.ceil(this.settingValues.length / this.visibleElements), this.page);
            this.checkEmptyItem();
        }
    }

    private slideDown() {
        if (this.page !== Math.ceil(this.settingValues.length / this.visibleElements)) {
            this.page++;
            this.values = PaginationHelper.pagination(this.page, this.settingValues, this.visibleElements);
            this.barStyle = PaginationHelper.setBarStyle(Math.ceil(this.settingValues.length / this.visibleElements), this.page);
            this.checkEmptyItem();
        }
    }

    private goBack() {
        this.$router.push({ path: window.location.pathname, query: { displayNav: RouteParamHelper.goBack(<string>this.$route.query.displayNav) } }).catch(() => { });
    }
}