import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import ResourceHelper from "@Scripts/Infrastructure/ResourceHelper";

@Component({ name: "TripUnitLibrary" })
export default class TripUnitLibrary extends Vue {
    private resourceHelper: any = ResourceHelper;

    @Prop() readonly tripUnitData: any;

    get culture() {
        return this.$store.getters["appResourceStore/culture"];
    }

    get appResources() {
        return this.$store.getters["appResourceStore/appResources"];
    }
};