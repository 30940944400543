import Vue from "vue";
import Vuex from "vuex";
import AppResourceStore from "@Scripts/Stores/AppResourceStore";
import SearchStore from "@Scripts/Stores/SearchStore";
import HeaderStore from "@Scripts/Stores/HeaderStore";
import CultureSelectorStore from "@Scripts/Stores/CultureSelectorStore";
import DeviceSettingStore from "@Scripts/Stores/DeviceSettingStore";
import DeviceNavigationStore from "@Scripts/Stores/DeviceNavigationStore";
import ReturnParametersStore from "@Scripts/Stores/ReturnParametersStore";
import ToastStore from "@Scripts/Stores/ToastStore";

Vue.use(Vuex);

export const store = new Vuex.Store({});

store.registerModule("appResourceStore", AppResourceStore);
store.registerModule("searchStore", SearchStore);
store.registerModule("headerStore", HeaderStore);
store.registerModule("cultureSelectorStore", CultureSelectorStore);
store.registerModule("deviceSettingStore", DeviceSettingStore);
store.registerModule("deviceNavigationStore", DeviceNavigationStore);
store.registerModule("returnParametersStore", ReturnParametersStore);
store.registerModule("app-toastStore", ToastStore);