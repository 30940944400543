import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import HtmlHelper from "@Scripts/Infrastructure/HtmlHelper";
import PaginationHelper from "@Scripts/Infrastructure/PaginationHelper";
import MediaHelper from "@Scripts/Infrastructure/MediaHelper";

@Component({ name: "ButtonList" })
export default class ButtonList extends Vue {
    private mediaHelper: any = MediaHelper;
    private controlStyles: any[] = [];
    private buttonsInPage: any[] = [];
    private page: number = 1;
    private visibleItems: any = null;
    private buttonIndexSelected: number = 0;
    private unsubscribe: any = null;

    get pin() {
        return this.$store.getters["deviceSettingStore/pin"];
    }

    get isSettingConfirmVisible() {
        return this.$store.getters["deviceSettingStore/isSettingConfirmVisible"];
    }

    @Prop() readonly screen: any;
    @Prop() readonly control: any;
    @Prop() readonly container: HTMLElement;
    @Prop() readonly isSmallDevice: boolean;
    @Prop() readonly tripunitimage: HTMLImageElement;

    @Watch("container") onContainerChanged(value: any) {
        this.getPercentagePositions();
    }

    @Watch("tripunitimage") onTripunitimageChanged(value: any) {
        this.getPercentagePositions();
    }

    @Watch("$route.query.displayNav") onRouteQueryDisplayNavChanged(value: string) {
        this.getPercentagePositions();
    }

    private created() {
        if (this.isSmallDevice) {
            this.visibleItems = this.screen.settings.visibleItems;

            for (let i = 0; i < this.screen.settings.items.length; i++) {
                this.screen.settings.items[i].index = i;
            }

            this.buttonsInPage = PaginationHelper.pagination(this.page, this.screen.settings.items, this.visibleItems);
        } else {
            this.buttonsInPage = this.screen.settings.items;
        }

        this.unsubscribe = (<any>this.$store).subscribeAction((action, state) => {
            if ((this.pin.isVisible && !this.pin.isCheked) || this.isSettingConfirmVisible) {
                return;
            }

            if (action.type === "deviceNavigationStore/onPressButtonEnter") {
                this.setAction(this.screen.settings.items[this.buttonIndexSelected]);
            } else if (action.type === "deviceNavigationStore/onPressButtonDown") {
                if (this.buttonIndexSelected >= 1) {
                    this.buttonIndexSelected--;
                }

                if (this.buttonIndexSelected % this.visibleItems !== 0) {
                    this.slideUp();
                    this.$emit("changeBackgroundImage", this.page - 1);
                }
            } else if (action.type === "deviceNavigationStore/onPressButtonUp") {
                if (this.buttonIndexSelected < (this.screen.settings.items.length - 1)) {
                    this.buttonIndexSelected++;
                }

                if (this.buttonIndexSelected % this.visibleItems === 0) {
                    this.slideDown();
                    this.$emit("changeBackgroundImage", this.page - 1);
                }
            }
        });
    }

    private mounted() {
        this.getPercentagePositions();
    }

    private beforeDestroy() {
        this.unsubscribe();
    }

    private getPercentagePositions() {
        let control = <HTMLElement>this.$refs.control;
        let vm = this;

        vm.controlStyles = [];
        for (const item of this.screen.settings.items) {
            HtmlHelper.onAllImagesLoaded(control, x => {
                if (!vm.tripunitimage) { return; }

                let positions = HtmlHelper.getButtonPercentagePositions(this.tripunitimage, item.position.x, item.position.y, item.size.width, item.size.height);

                vm.controlStyles.push("top:" + positions.y + "%; left:" + positions.x + "%; width:" + positions.width + "%; height:" + positions.height + "%;");
            });
        }
    }

    private slideUp() {
        if (this.page > 1) {
            this.page--;
            this.buttonsInPage = PaginationHelper.pagination(this.page, this.screen.settings.items, this.visibleItems);

            this.getPercentagePositions();
        }
    }

    private slideDown() {
        if (this.page !== Math.ceil(this.screen.settings.items.length / this.visibleItems)) {
            this.page++;
            this.buttonsInPage = PaginationHelper.pagination(this.page, this.screen.settings.items, this.visibleItems);

            this.getPercentagePositions();
        }
    }

    private setAction(item: any) {
        if (item.type === "Navigation") {
            this.$router.push({ path: window.location.pathname, query: { displayNav: item.actionValue } }).catch(() => { });
        }
    }
}