export default class RouteParamHelper {
    static goBack(displayNav: string, isDeviceSmall: boolean = false): string {
        let arrayDisplayNav = (displayNav).split(":");
        if (arrayDisplayNav.length >= 2) {
            arrayDisplayNav.pop();
        } else if (isDeviceSmall) {
            arrayDisplayNav = ["Home", "Settings"];
        }
        return arrayDisplayNav.join(":");
    }
}