import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({ name: "SettingConfirm" })
export default class SettingConfirm extends Vue {
    private list: any[] = [];
    private buttonIndexSelected: number = 0;
    private unsubscribe: any = null;

    get appResources() {
        return this.$store.getters["appResourceStore/appResources"];
    }

    get settings() {
        return this.$store.getters["deviceSettingStore/settings"];
    }

    get cacheSettings() {
        return this.$store.getters["deviceSettingStore/cacheSettings"];
    }

    get lastSettingPath() {
        return this.$store.getters["deviceSettingStore/lastSettingPath"];
    }

    @Prop() readonly isSmallDevice: boolean;

    private created() {
        this.list = [...new Set(this.cacheSettings.map(function (item) {
            return item["settingName"];
        }))].slice(0, 14);

        this.unsubscribe = (<any>this.$store).subscribeAction((action, state) => {
            if (action.type === "deviceNavigationStore/onPressButtonEnter") {
                switch (this.buttonIndexSelected) {
                    case 0:
                        this.confirm();
                        break;
                    case 1:
                        this.abort();
                        break;
                    case 2:
                        this.modify();
                        break;
                }
            } else if (action.type === "deviceNavigationStore/onPressButtonDown") {
                if (this.buttonIndexSelected >= 1) {
                    this.buttonIndexSelected--;
                }
            } else if (action.type === "deviceNavigationStore/onPressButtonUp") {
                if (this.buttonIndexSelected < 2) {
                    this.buttonIndexSelected++;
                }
            }
        });
    }

    private beforeDestroy() {
        this.unsubscribe();
    }

    private confirm() {
        this.$store.dispatch("app-toastStore/onAddToast", { message: this.appResources.App.ParametersUpdated, type: "Info", isIconHidden: this.isSmallDevice });

        let newSettings = this.settings;
        for (const setting of this.cacheSettings) {
            let index = this.settings.findIndex(x => x.name == setting.name);
            if (index !== "-1") {
                newSettings[index].value = setting.value;
            }
        }

        this.$store.commit("deviceSettingStore/setSettings", newSettings);
        this.$store.dispatch("deviceSettingStore/resetCacheSettings");

        this.$store.commit("deviceSettingStore/setSettingConfirmVisible", false);
    }

    private abort() {
        this.$store.dispatch("app-toastStore/onAddToast", { message: this.appResources.App.Aborted, type: "Info", isIconHidden: this.isSmallDevice });
        this.$store.dispatch("deviceSettingStore/resetCacheSettings");

        this.$store.commit("deviceSettingStore/setSettingConfirmVisible", false);
    }

    private modify() {
        this.$router.push({ path: window.location.pathname, query: { displayNav: this.lastSettingPath } }).catch(() => { });
        this.$store.commit("deviceSettingStore/setSettingConfirmVisible", false);
    }
};