import Vue from "vue";
import Component from "vue-class-component";
import Axios from "axios";
import ResourceHelper from "@Scripts/Infrastructure/ResourceHelper";
import MediaHelper from "@Scripts/Infrastructure/MediaHelper";

@Component({ name: "TripUnitList" })
export default class TripUnitList extends Vue {
    private resourceHelper: any = ResourceHelper;
    private mediaHelper: any = MediaHelper;
    private totalCircuitBreakers: any[] = [];
    private circuitBreakers: any[] = [];
    private tripUnits: any[] = [];
    private filteredTripUnits: any[] = [];
    private selectedCircuitBreaker: any = null;
    private isLoading: boolean = false;
    private isCreateLoading: boolean = true;
    private familyItems: any[] = [];
    private returnParameters: any = {};

    get culture() {
        return this.$store.getters["appResourceStore/culture"];
    }

    get appResources() {
        return this.$store.getters["appResourceStore/appResources"];
    }

    get searchData() {
        return this.$store.getters["searchStore/searchData"];
    }

    get header() {
        return this.$store.getters["headerStore/isHeader"];
    }

    get isCultureSelectorVisible() {
        return this.$store.getters["cultureSelectorStore/isCultureSelectorVisible"];
    }

    private async created() {
        const vm = this;
        await Promise.all([this.getTripUnits(), this.getCircuitBreakers()]);

        this.getFamilyItems();
        this.setParameters(false);

        window.addEventListener("popstate", function (event) {
            vm.setParameters(false);
        });
        this.isCreateLoading = false;
    }

    private async getTripUnits() {
        let response = await Axios.get("/data/tripunits/tripunits.json");
        this.tripUnits = response.data;
    }

    private async getCircuitBreakers() {
        let response = await Axios.get("/data/circuitbreakers/circuitbreakers.json");
        this.totalCircuitBreakers = response.data;
    }

    private getFamilyItems() {
        this.familyItems = this.tripUnits.map(x => { return { title: x.family, value: x.family } }).filter((item, i, items) => items.findIndex(t => (t.title === item.title)) === i);
    }

    private searchByCircuitBreaker(circuitBreaker: string) {
        this.searchData.circuitBreaker = circuitBreaker;
        this.searchData.isFilter = true;

        this.updateSearch();
    }

    private setParameters(refresh: boolean) {
        var family = <string>this.$route.query.family;
        var circuitBreaker = <string>this.$route.query.circuitBreaker;
        var standard = <string>this.$route.query.standard;
        var type = <string>this.$route.query.type;
        var protection = <string>this.$route.query.protection;
        var application = <string>this.$route.query.application;

        this.searchData.family = family ? family : null;
        this.searchData.circuitBreaker = family && circuitBreaker ? circuitBreaker : null;
        this.searchData.standard = family && standard ? standard : null;
        this.searchData.type = family && type ? type : null;
        this.searchData.protection = family && protection ? protection : null;
        this.searchData.application = family && application ? application : null;

        this.searchData.isFilter = this.isFilter();

        this.updateSearch(refresh);
    }

    private isFilter(): boolean {
        return (this.searchData.circuitBreaker || this.searchData.standard || this.searchData.type || this.searchData.protection || this.searchData.application) ? true : false;
    }

    private updateSearch(refresh: boolean = true) {
        this.isLoading = true;
        this.returnParameters = {};
        const queryParameters: string[] = [];

        this.filteredTripUnits = this.tripUnits.filter(x => x.family === this.searchData.family);

        if (this.searchData.family) {
            this.circuitBreakers = this.totalCircuitBreakers.filter(x => x.family === this.searchData.family);
            queryParameters.push(`family=${this.searchData.family}`);
            this.returnParameters.family = this.searchData.family;
        }

        if (this.searchData.circuitBreaker) {
            this.filteredTripUnits = this.filteredTripUnits.filter(x => x.circuitBreaker === this.searchData.circuitBreaker);
            this.selectedCircuitBreaker = this.circuitBreakers.find(x => x.name === this.searchData.circuitBreaker);
            queryParameters.push(`circuitBreaker=${this.searchData.circuitBreaker}`);
            this.returnParameters.circuitBreaker = this.searchData.circuitBreaker;
        } else {
            this.selectedCircuitBreaker = null;
        }

        if (this.searchData.standard) {
            if (this.searchData.standard !== 'All') {
                this.filteredTripUnits = this.filteredTripUnits.filter(x => x.standard === this.searchData.standard);
            }
            queryParameters.push(`standard=${this.searchData.standard}`);
            this.returnParameters.standard = this.searchData.standard;
        }

        if (this.searchData.type) {
            this.filteredTripUnits = this.filteredTripUnits.filter(x => x.type === this.searchData.type);
            queryParameters.push(`type=${this.searchData.type}`);
            this.returnParameters.type = this.searchData.type;
        }

        if (this.searchData.protection) {
            this.filteredTripUnits = this.filteredTripUnits.filter(x => x.protection === this.searchData.protection);
            queryParameters.push(`protection=${this.searchData.protection}`);
            this.returnParameters.protection = this.searchData.protection;
        }

        if (this.searchData.application) {
            this.filteredTripUnits = this.filteredTripUnits.filter(x => x.application === this.searchData.application);
            queryParameters.push(`application=${this.searchData.application}`);
            this.returnParameters.application = this.searchData.application;
        }

        this.$store.commit('searchStore/setSearchData', this.searchData);

        const queryUrl = queryParameters.length > 0 ? '?' + queryParameters.join('&') : '';

        if (refresh) {
            history.pushState({}, '', `${location.pathname}${queryUrl}`);
        }

        this.isLoading = false;
    }

    private setFamily(family: string) {
        this.searchData.family = family;
        this.updateSearch();
    }

    private setDetail(name: string) {
        this.$store.commit('returnParametersStore/setReturnParameters', this.returnParameters);
        this.$router.push({ name: 'TripUnitDetail', params: { name: name.toLowerCase() } }).catch(() => { });
    }
};