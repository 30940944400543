const state = {
    isSettingConfirmVisible: false,
    isPinChecked: false,
    pin: { isCheked: false, isVisible: false },
    settings: [],
    cacheSettings: [],
    lastSettingPath: null,
    nominalCurrent: null
};

const getters = {
    isSettingConfirmVisible: state => state.isSettingConfirmVisible,
    isPinChecked: state => state.isPinChecked,
    pin: state => state.pin,
    settings: state => state.settings,
    cacheSettings: state => state.cacheSettings,
    lastSettingPath: state => state.lastSettingPath,
    nominalCurrent: state => state.nominalCurrent
};

const mutations = {
    setSettingConfirmVisible: (state, data) => state.isSettingConfirmVisible = data,
    setPinChecked: (state, data) => state.pin.isCheked = data,
    setPinVisible: (state, data) => state.pin.isVisible = data,
    setSettings: (state, data) => state.settings = data,
    setCacheSettings: (state, data) => state.cacheSettings = data,
    setLastSettingPath: (state, data) => state.lastSettingPath = data,
    setNominalCurrent: (state, data) => state.nominalCurrent = data
};

const actions = {
    addCacheSettings: (context, data) => {
        let cache = context.getters["cacheSettings"];
        let index = cache.findIndex(x => x.name === data.name);
        if (index === -1) {
            cache.push(data);
            context.commit("setCacheSettings", cache);
        } else {
            cache[index].value = data.value;
        }
    },
    removeCacheSettings: (context, data) => {
        let cache = context.getters["cacheSettings"];
        context.commit("setCacheSettings", cache.filter(x => x.name !== data));
    },
    resetCacheSettings: (context, data) => {
        context.commit("setCacheSettings", []);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};