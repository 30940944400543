import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import MediaHelper from "@Scripts/Infrastructure/MediaHelper";

@Component({ name: "ToastHub" })
export default class ToastHub extends Vue {
    private mediaHelper: any = MediaHelper;

    @Prop() readonly isSmallDevice: boolean;

    get toast(): any {
        return this.$store.getters["app-toastStore/toast"]
    }

    private created() {
        (<any>this.$store).subscribeAction((action, state) => {
            // Add toast 
            if (action.type === "app-toastStore/onAddToast") {
                this.addToast(action.payload);
            }
        });
    }

    private addToast(toast: any) {
        this.$store.commit("app-toastStore/setToast", toast);

        setTimeout(() => {
            this.dismissToast();
        }, 3500, toast);
    }

    private dismissToast() {
        this.$store.commit("app-toastStore/setToast", null);
    }
};