import "@Styles/Main.less"

import BasePage from "@Scripts/Pages/BasePage";

let App: any = {
    instances: {},
    pages: {},
    routes: {},
    viewModels: {},
    resources: {}
};

App.pages.BasePage = BasePage;

(<any>window).App = App;