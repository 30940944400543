import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import ResourceHelper from "@Scripts/Infrastructure/ResourceHelper";
import DIPSwitchListHelper from "@Scripts/Infrastructure/DIPSwitchListHelper";

@Component({ name: "TripUnitSummary" })
export default class TripUnitSummary extends Vue {
    private resourceHelper: any = ResourceHelper;
    private summaryData: any = { si: null };
    private nominalCurrent: number = null;
    private isDisableIselector: boolean = false;
    private isDisableSselector: boolean = false;
    private isTooltip: boolean = false;
    private isSummeryDisable: boolean = false;
    private isChartDisable: boolean = false;

    @Prop() readonly tripUnitData: any;
    @Prop() readonly isSummaryUpdate: boolean;

    @Watch("isSummaryUpdate") onSummaryUpdateChanged(value: boolean) {
        this.setSummaryData();
    }

    @Watch("tripUnitData") onTripUnitDataChanged(value: boolean) {
        this.initNominalCurrent();
        this.setSummaryData();
    }

    get culture() {
        return this.$store.getters["appResourceStore/culture"];
    }

    get appResources() {
        return this.$store.getters["appResourceStore/appResources"];
    }

    private created() {
        this.isSummeryDisable = this.tripUnitData.isSummeryDisable ? this.tripUnitData.isSummeryDisable : false;
        this.isChartDisable = this.tripUnitData.isChartDisable ? this.tripUnitData.isChartDisable : false;
        this.initNominalCurrent();
    }

    private initNominalCurrent() {
        this.nominalCurrent = this.tripUnitData.nominalCurrent.value;
        this.setSummaryData();
    }

    private setSummaryData() {
        this.summaryData = {
            isProtectionL: this.tripUnitData.protections.filter(x => x === "L").length !== 0,
            isProtectionS: this.tripUnitData.protections.filter(x => x === "S").length !== 0,
            isProtectionI: this.tripUnitData.protections.filter(x => x === "I").length !== 0,
            isProtectionU: this.tripUnitData.protections.filter(x => x === "U").length !== 0,
            isProtectionG: this.tripUnitData.protections.filter(x => x === "G").length !== 0,
            isNeutral: this.tripUnitData.isNeutral,
            isParameters: this.tripUnitData.isParameters,
            isFrequency: this.tripUnitData.isFrequency,
            nominalCurrents: this.tripUnitData.nominalCurrent.items,
            isSISelector: this.tripUnitData.isSISelector
        };

        if (!this.isSummeryDisable) {
            this.setSummaryProtectionData();
        }

        this.$emit("updateComplete");
    }

    private setSummaryProtectionData() {
        if (this.summaryData.isProtectionL) {
            this.summaryData.protectionL = { i1: this.getControlValue("i1") };
            this.summaryData.protectionL.i1a = this.getCurrentIntensity(this.summaryData.protectionL.i1);
            this.summaryData.protectionL.t1 = this.getControlValue("t1");
            this.summaryData.protectionL.configurations = this.getControlValues("i1");
            this.summaryData.protectionL.timeConfigurations = this.getControlValues("t1");
            this.summaryData.protectionL.mode = this.getControlMode("t1");
            this.summaryData.protectionL.dynamicInformation = this.getDynamicInformation("i1");
        }

        if (this.summaryData.isProtectionS) {
            this.summaryData.protectionS = { i2: this.getControlValue("i2") };
            this.summaryData.protectionS.i2a = this.getCurrentIntensity(this.summaryData.protectionS.i2);
            this.summaryData.protectionS.t2 = this.getControlValue("t2");
            this.summaryData.protectionS.t2c = this.getControlValue("t2c");
            this.summaryData.protectionS.timeConfigurations = this.getControlValues("t2");
            this.summaryData.protectionS.mode = this.getControlMode("t2");
            this.summaryData.protectionS.configurations = this.getControlValues("i2");
            this.summaryData.protectionS.dynamicInformation = this.getDynamicInformation("i2");
        }

        if (this.summaryData.isProtectionI) {
            this.summaryData.protectionI = { i3: this.getControlValue("i3") };
            this.summaryData.protectionI.i3a = this.getCurrentIntensity(this.summaryData.protectionI.i3);
            this.summaryData.protectionI.configurations = this.getControlValues("i3");
            this.summaryData.protectionI.dynamicInformation = this.getDynamicInformation("i3");
        }

        if (this.summaryData.isProtectionU) {
            this.summaryData.protectionU = { u1: this.getControlValue("u1") };
            this.summaryData.protectionU.dynamicInformation = this.getDynamicInformation("u1");
        }

        if (this.summaryData.isProtectionG) {
            this.summaryData.protectionG = { i4: this.getControlValue("i4") };
            this.summaryData.protectionG.i4a = this.getCurrentIntensity(this.summaryData.protectionG.i4);
            this.summaryData.protectionG.t4 = this.getControlValue("t4");
            this.summaryData.protectionG.t4c = this.getControlValue("t4c");
            this.summaryData.protectionG.timeConfigurations = this.getControlValues("t4");
            this.summaryData.protectionG.mode = this.getControlMode("t4");
            this.summaryData.protectionG.configurations = this.getControlValues("i4");
            this.summaryData.protectionG.dynamicInformation = this.getDynamicInformation("i4");
        }

        if (this.summaryData.isNeutral) {
            this.summaryData.n = this.getControlValue("N");
        }

        if (this.summaryData.isFrequency) {
            this.summaryData.hz = this.getControlValue("hz");
        }

        if (this.summaryData.isParameters) {
            this.summaryData.remLoc = this.getControlValue("RemLoc");
            this.summaryData.manElt = this.getControlValue("ManElt");
        }

        if (this.summaryData.isSISelector) {
            this.summaryData.si = this.getControlValue("si");
            if (this.summaryData.si === "I") {
                this.isDisableSselector = true;
                this.isDisableIselector = false;
            } else if (this.summaryData.si === "S") {
                this.isDisableSselector = false;
                this.isDisableIselector = true;
            }
        }

        this.checkIsTooltip();
    }

    private getControlValue(controlName: string): any {
        let value: any = null;

        let controls = this.tripUnitData.controls.filter(x => x.name === controlName);
        if (controls.length !== 0 && typeof controls[0].value == "string") {
            value = controls[0].value;
        } else if (controls.length !== 0 && typeof controls[0].value == "number") {
            value = DIPSwitchListHelper.roundNumber(controls[0].value, 3);
        }

        return value;
    }

    private getControlMode(controlName: string): string {
        let controls = this.tripUnitData.controls.filter(x => x.name === controlName);

        return controls[0].mode;
    }

    private getCurrentIntensity(currentThreshold: number): number {
        return this.nominalCurrent * currentThreshold;
    }

    private getControlValues(controlName: string): any[] {
        let values: number[] = [];

        let control = this.tripUnitData.controls.filter(x => x.name === controlName)[0];

        switch (control.mode) {
            case "Configuration":
            case "TimeConfiguration":
                let configurationValues = control.configurations.map(x => { return x.value });

                const molt = control.mode === "TimeConfiguration" ? 1 : this.nominalCurrent;
                for (const configurationValue of configurationValues) {
                    values.push(configurationValue * molt);
                }

                break;
            case "Sum":
                let computedConfigurations = DIPSwitchListHelper.getComputedConfigurations(control.items.length);

                for (const computedConfiguration of computedConfigurations) {
                    let currentThreshold = DIPSwitchListHelper.getSumValueFromConfiguration(control, computedConfiguration);
                    values.push(currentThreshold * this.nominalCurrent);
                }

                break;
        }

        // Removes duplicate values generated by cap limit
        values = values.filter((item, index) => values.indexOf(item) === index);

        return values.sort((one, two) => (one < two ? -1 : 1));
    }

    private getDynamicInformation(controlName: string): string {
        return this.tripUnitData.controls.filter(x => x.name === controlName)[0].dynamicInformation;
    }

    private checkIsTooltip() {
        if (this.summaryData.isProtectionL && this.summaryData.protectionL.dynamicInformation) {
            this.isTooltip = true;
        }
        if (this.summaryData.isProtectionS && this.summaryData.protectionS.dynamicInformation) {
            this.isTooltip = true;
        }
        if (this.summaryData.isProtectionI && this.summaryData.protectionI.dynamicInformation) {
            this.isTooltip = true;
        }
        if (this.summaryData.isProtectionG && this.summaryData.protectionG.dynamicInformation) {
            this.isTooltip = true;
        }
        if (this.summaryData.isProtectionU && this.summaryData.protectionU.dynamicInformation) {
            this.isTooltip = true;
        }
    }

    private setNominalCurrent() {
        this.$store.commit("deviceSettingStore/setNominalCurrent", this.nominalCurrent);
        this.setSummaryData();
    }

    private setControlValue(controlName: string, value: number) {
        let control = this.tripUnitData.controls.filter(x => x.name === controlName)[0];

        const multiplier = control.mode === "TimeConfiguration" ? 1 : this.nominalCurrent;
        control.value = DIPSwitchListHelper.roundNumber((value / multiplier), 3);

        this.setSummaryData();
    }
};