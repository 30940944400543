export default class ResourceHelper {
    static get(resource: any, culture: string): string {
        let value: string = null;

        if (resource) {
            let cultures = resource.filter(x => x.culture === culture);
            if (cultures.length !== 0) {
                value = cultures[0].value;
            }

            if (!value) {
                // Fallback to default culture
                cultures = resource.filter(x => x.culture === "en-GB");
                if (cultures.length !== 0) {
                    value = cultures[0].value;
                }
            }
        }

        return value;
    }
}