import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import RouteParamHelper from "@Scripts/Infrastructure/RouteParamHelper";
import MediaHelper from "@Scripts/Infrastructure/MediaHelper";
import PaginationHelper from "@Scripts/Infrastructure/PaginationHelper";

@Component({ name: "ItemList" })
export default class ItemList extends Vue {
    private mediaHelper: any = MediaHelper;
    private listInPage: any[] = [];
    private page: number = 1;
    private list: any[] = [];
    private visibleElements: number = 9;
    private barStyle: string = null;
    private buttonIndexSelected: number = 0;
    private unsubscribe: any = null;

    get pin() {
        return this.$store.getters["deviceSettingStore/pin"];

    }

    get isSettingConfirmVisible() {
        return this.$store.getters["deviceSettingStore/isSettingConfirmVisible"];
    }

    @Prop() readonly screen: any;
    @Prop() readonly isSmallDevice: boolean;

    private created() {
        this.visibleElements = this.isSmallDevice ? 2 : 9;
        this.list = this.screen.settings.items;
        this.listInPage = PaginationHelper.pagination(this.page, this.list, this.visibleElements);
        this.barStyle = PaginationHelper.setBarStyle(Math.ceil(this.list.length / this.visibleElements), this.page);

        this.unsubscribe = (<any>this.$store).subscribeAction((action, state) => {
            if ((this.pin.isVisible && !this.pin.isCheked) || this.isSettingConfirmVisible) {
                return;
            }

            if (action.type === "deviceNavigationStore/onPressButtonDown") {
                if (this.buttonIndexSelected < (this.screen.settings.items.length - 1)) {
                    this.buttonIndexSelected = this.buttonIndexSelected + 2;
                }

                if (this.buttonIndexSelected % this.visibleElements === 0) {
                    this.slideDown();
                }
            } else if (action.type === "deviceNavigationStore/onPressButtonUp") {
                if (this.buttonIndexSelected >= 2) {
                    this.buttonIndexSelected = this.buttonIndexSelected - 2;
                }

                if (this.buttonIndexSelected % this.visibleElements === 0) {
                    this.slideUp();
                }
            }
        });

        this.checkEmptyItem();
    }

    private beforeDestroy() {
        this.unsubscribe();
    }

    private checkEmptyItem() {
        let totElement = this.visibleElements * Math.ceil(this.list.length / this.visibleElements);
        let emptyObjsNumber = totElement - this.list.length;

        for (let index = 0; index < emptyObjsNumber; index++) {
            this.list.push({});
        }
    }

    private slideUp() {
        if (this.page > 1) {
            this.page--;
            this.listInPage = PaginationHelper.pagination(this.page, this.list, this.visibleElements);
            this.barStyle = PaginationHelper.setBarStyle(Math.ceil(this.list.length / this.visibleElements), this.page);
        }
    }

    private slideDown() {
        if (this.page !== Math.ceil(this.list.length / this.visibleElements)) {
            this.page++;
            this.listInPage = PaginationHelper.pagination(this.page, this.list, this.visibleElements);
            this.barStyle = PaginationHelper.setBarStyle(Math.ceil(this.list.length / this.visibleElements), this.page);
        }
    }

    private goBack() {
        this.$router.push({ path: window.location.pathname, query: { displayNav: RouteParamHelper.goBack(<string>this.$route.query.displayNav) } }).catch(() => { });
    }
}