export default class HtmlHelper {
    static getImagePercentage(image: HTMLElement, container: HTMLElement): any {
        let imageRect = image.getBoundingClientRect();
        let containerRect = container.getBoundingClientRect();
        return (imageRect.width / containerRect.width) * 100;
    }

    static getPercentagePositions(container: HTMLElement, x: number, y: number, width: number, height?: number): any {
        let containerRect = <DOMRect>container.getBoundingClientRect();

        return {
            x: (100 * x) / containerRect.width,
            y: (100 * y) / containerRect.height,
            width: (100 * width) / containerRect.width,
            height: height ? (100 * height) / containerRect.height : null
        };
    }

    static getButtonPercentagePositions(container: HTMLImageElement, x: number, y: number, width: number, height?: number): any {
        return {
            x: (100 * x) / container.naturalWidth,
            y: (100 * y) / container.naturalHeight,
            width: (100 * width) / container.naturalWidth,
            height: height ? (100 * height) / container.naturalHeight : null
        };
    }

    static onAllImagesLoaded(element: HTMLElement, callbackFunction: Function) {
        let images = element.querySelectorAll("img");
        let imageLoadedCount = 0;
        const imageTotalCount = images.length;

        if (imageTotalCount === 0) {
            callbackFunction();
        } else {
            [].forEach.call(images, function (img) {
                if (img.complete) {
                    incrementCounter();
                } else {
                    img.addEventListener('load', incrementCounter, false);
                }
            });

            function incrementCounter() {
                imageLoadedCount++;

                if (imageLoadedCount === imageTotalCount) {
                    callbackFunction();
                }
            }
        }
    }
}