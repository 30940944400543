import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

@Component({ name: "TripUnitMenu" })
export default class TripUnitMenu extends Vue {
    private selectedSectionName: string = null;

    get appResources() {
        return this.$store.getters["appResourceStore/appResources"];
    }

    get returnParameters() {
        return this.$store.getters["returnParametersStore/returnParameters"];
    }

    @Prop() readonly tripUnitData: any;

    @Watch("$route") onRouteChanged(value: any, oldValue: any) {
        this.selectedSectionName = this.$route.meta.sectionName;
    }

    private created() {
        this.selectedSectionName = this.$route.meta.sectionName;
    }

    private setSectionNavigation(sectionName: string) {
        this.$router.push({ name: sectionName, params: { name: this.tripUnitData.name.toLowerCase() } }).catch(() => { });
    }

    private goBack() {
        this.$router.push({ name: "TripUnitList", query: this.returnParameters }).catch(() => { });
    }
};