const state = {
    toast: null
};

const getters = {
    toast: state => state.toast
};

const mutations = {
    setToast: (state, data) => state.toast = data
};

const actions = {
    onAddToast: (context, data) => { }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};