export default class DIPSwitchListHelper {
    static getConfigurationFromValue(control: any, value: any): boolean[] {
        let configuration = this.initConfiguration(control);

        switch (control.mode) {
            case "Configuration":
            case "TimeConfiguration":
                if (control.value !== null) {
                    let serializedConfiguration: string = null;

                    let selectedConfigurations: any[] = control.configurations.filter(x => x.value == value);
                    if (selectedConfigurations.length !== 0) {
                        serializedConfiguration = selectedConfigurations[0].configuration;
                    }

                    configuration = this.deserializeConfiguration(serializedConfiguration)
                }
                break;
            case "Sum":
                configuration = this.getConfigurationFromSumValue(control, parseFloat(value));
                break;
        }

        return configuration;
    }

    static getConfigurationFromSumValue(control: any, value: number): boolean[] {
        let configuration = this.initConfiguration(control);

        let computedConfigurations = this.getComputedConfigurations(control.items.length);

        for (const computedConfiguration of computedConfigurations) {
            if (this.getSumValueFromConfiguration(control, computedConfiguration) === value) {
                configuration = computedConfiguration;
                break;
            }
        }

        return configuration;
    }

    static getValueFromConfiguration(control: any, configuration: boolean[]): any {
        let value: any = null;

        switch (control.mode) {
            case "Configuration":
            case "TimeConfiguration":
                let serializedConfiguration = this.serializeConfiguration(configuration);

                let selectedConfigurations: any[] = control.configurations.filter(x => x.configuration === serializedConfiguration);
                if (selectedConfigurations.length !== 0) {
                    value = selectedConfigurations[0].value;
                }
                break;
            case "Sum":
                value = this.getSumValueFromConfiguration(control, configuration);
                break;
        }

        return value;
    }

    static getSumValueFromConfiguration(control: any, configuration: boolean[]): number {
        let value: number = 0;

        if (control.baseValue) {
            value = this.roundNumber(value + control.baseValue, 3);
        }

        for (let index = 0; index < control.items.length; index++) {
            const item = control.items[index];

            if (configuration[index]) {
                value = this.roundNumber(value + item, 3);
            }
        }

        if (control.capLimit && value > control.capLimit) {
            value = control.capLimit;
        }

        return value;
    }

    static getComputedConfigurations(dipSwitchCount: number): any[] {
        let configurations: any[] = [];

        for (let i = 0; i < (1 << dipSwitchCount); i++) {
            let boolArr = [];

            // Increasing or decreasing depending on which direction you want your array to represent the binary number
            for (let j = dipSwitchCount - 1; j >= 0; j--) {
                boolArr.push(Boolean(i & (1 << j)));
            }

            if (boolArr.length === dipSwitchCount) {
                configurations.push(boolArr);
            }
        }

        return configurations;
    }

    static initConfiguration(control: any): boolean[] {
        let configuration = [];

        for (const item of control.items) {
            configuration.push(false);
        }

        return configuration;
    }

    static serializeConfiguration(configuration: boolean[]): string {
        let serializedConfiguration: string = "";

        for (const item of configuration) {
            serializedConfiguration = serializedConfiguration + (item === true ? "1" : "0");
        }

        return serializedConfiguration;
    }

    static deserializeConfiguration(serializedConfiguration: string): boolean[] {
        let configuration: boolean[] = [];

        for (const item of Array.from(serializedConfiguration)) {
            configuration.push(item === "1");
        }

        return configuration;
    }

    static roundNumber(number: number, decimals: number): number {
        return parseFloat(new Number(number + '').toFixed(decimals));
    }
}