import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import RouteParamHelper from "@Scripts/Infrastructure/RouteParamHelper";

@Component({ name: "PinCode" })
export default class PinCode extends Vue {
    private correctPin: string = "00001";
    private pinCode: any = { "0": 0, "1": 0, "2": 0, "3": 0, "4": 0 };
    private buttonIndexSelected: number = 0;
    private unsubscribe: any = null;

    get appResources() {
        return this.$store.getters["appResourceStore/appResources"];
    }

    get pin() {
        return this.$store.getters["deviceSettingStore/pin"];
    }

    @Prop() readonly isSmallDevice: boolean;

    private created() {
        this.unsubscribe = (<any>this.$store).subscribeAction((action, state) => {
            if (action.type === "deviceNavigationStore/onPressButtonEnter") {
                if (this.buttonIndexSelected === 4) {
                    this.confirmPin();
                    return;
                }
                this.buttonIndexSelected++;
            } else if (action.type === "deviceNavigationStore/onPressButtonDown") {
                this.less(this.buttonIndexSelected);
            } else if (action.type === "deviceNavigationStore/onPressButtonUp") {
                this.plus(this.buttonIndexSelected);
            }
        });
    }

    private beforeDestroy() {
        this.unsubscribe();
    }

    private plus(propertyName: number) {
        if (this.pinCode[propertyName] >= 0 && this.pinCode[propertyName] < 9) {
            this.pinCode[propertyName]++;
        } else {
            this.pinCode[propertyName] = 0;
        }
    }

    private less(propertyName: number) {
        if (this.pinCode[propertyName] > 0 && this.pinCode[propertyName] <= 9) {
            this.pinCode[propertyName]--;
        } else {
            this.pinCode[propertyName] = 9;
        }
    }

    private confirmPin() {
        if (Object.values(this.pinCode).join('') === this.correctPin) {
            this.$store.commit("deviceSettingStore/setPinChecked", true);
            this.$store.commit("deviceSettingStore/setPinVisible", false);
        } else {
            this.$store.dispatch("app-toastStore/onAddToast", { message: this.appResources.App.WrongPIN, type: "Error" });
            this.buttonIndexSelected = 0;
            this.pinCode = { "0": 0, "1": 0, "2": 0, "3": 0, "4": 0 };
        }
    }

    private goBack() {
        this.$router.push({ path: window.location.pathname, query: { displayNav: RouteParamHelper.goBack(<string>this.$route.query.displayNav) } }).catch(() => { });
    }
}