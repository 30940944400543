export default class PaginationHelper {
    static pagination(page: number, itemList: any[], visibleElements: number) {
        let pageIndex = page - 1;
        return itemList.slice(pageIndex * visibleElements, page * visibleElements);
    }

    static setBarStyle(totPage: number, currentPage: number): string {
        let thickness = 100 / totPage;
        let top = null;

        if (currentPage === 1) {
            top = "top: 0;";
        } else {
            top = "top: " + ((thickness * currentPage) - thickness) + "%;";
        }

        return "height: " + thickness + "%; " + top;
    }
}