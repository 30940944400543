const state = {
    searchData: {
        isFilter: false,
        family: null,
        circuitBreaker: null,
        standard: null,
        type: null,
        protection: null,
        application: null
    }
};

const getters = {
    searchData: state => state.searchData
};

const mutations = {
    setSearchData: (state, data) => state.searchData = data,
    resetSearchData: (state, data) => {
        const s = initialState(data);
        Object.keys(s).forEach(key => {
            state[key] = s[key];
        })
    }
};

const actions = {
};

function initialState(data: any) {
    return {
        searchData: {
            isFilter: false,
            family: null,
            circuitBreaker: null,
            standard: null,
            type: null,
            protection: null,
            application: null
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};