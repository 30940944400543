import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import RouteParamHelper from "@Scripts/Infrastructure/RouteParamHelper";
import DIPSwitchListHelper from "@Scripts/Infrastructure/DIPSwitchListHelper";

@Component({ name: "NumericSelector" })
export default class NumericSelector extends Vue {
    private settingName: string = null;
    private coefficient: string = null;
    private settingExtremities: number[] = [];
    private settingValue: any = 0;
    private step: number = 1;
    private decimalCount: number = 1;
    private unsubscribe: any = null;

    get appResources() {
        return this.$store.getters["appResourceStore/appResources"];
    }

    get settings() {
        return this.$store.getters["deviceSettingStore/settings"];
    }

    get cacheSettings() {
        return this.$store.getters["deviceSettingStore/cacheSettings"];
    }

    get nominalCurrent() {
        return this.$store.getters["deviceSettingStore/nominalCurrent"];
    }

    get pin() {
        return this.$store.getters["deviceSettingStore/pin"];
    }

    get isSettingConfirmVisible() {
        return this.$store.getters["deviceSettingStore/isSettingConfirmVisible"];
    }

    @Prop() readonly control: any;
    @Prop() readonly screen: any;
    @Prop() readonly isSmallDevice: boolean;

    @Watch("nominalCurrent") onNominalCurrentChanged() {
        this.setCoefficient();
    }

    private created() {
        this.settingName = this.screen.settings.settingName;
        this.settingExtremities = this.screen.settings.values;
        this.decimalCount = this.getDecimalCount(this.settingExtremities[0]);
        this.step = this.screen.settings.step ? parseFloat(this.screen.settings.step) : this.step;
        this.settingValue = this.getSettingValue(this.settingName);

        this.setCoefficient();

        this.$store.commit("deviceSettingStore/setLastSettingPath", <string>this.$route.query.displayNav);

        this.unsubscribe = (<any>this.$store).subscribeAction((action, state) => {
            if ((this.pin.isVisible && !this.pin.isCheked) || this.isSettingConfirmVisible) {
                return;
            }

            if (action.type === "deviceNavigationStore/onPressButtonEnter") {
                this.confirm();
            } else if (action.type === "deviceNavigationStore/onPressButtonDown") {
                this.less();
            } else if (action.type === "deviceNavigationStore/onPressButtonUp") {
                this.plus();
            }
        });
    }

    private beforeDestroy() {
        this.unsubscribe();
    }

    private setCoefficient() {
        switch (this.screen.settings.moltiplicator) {
            case "nominalCurrent":
                this.coefficient = DIPSwitchListHelper.roundNumber(parseFloat(this.settingValue) * this.nominalCurrent, 0) + " A";
                break;
            case "coefficient":
                this.coefficient = DIPSwitchListHelper.roundNumber(parseFloat(this.settingValue) * parseFloat(this.screen.settings.coefficient), 0) + " " + this.screen.settings.unitsOfMeasurementOfCoefficient;
                break;
        }
    }

    private getDecimalCount(num: any) {
        const numStr = String(num);
        if (numStr.includes('.')) {
            return numStr.split('.')[1].length;
        };
        return 0;
    }

    private getSettingValue(settingName: any) {
        if (this.cacheSettings.some(x => x.name === settingName)) {
            return this.cacheSettings.find(x => x.name === settingName).value;
        } else {
            return this.settings.find(x => x.name === settingName)?.value;
        }
    }

    private less() {
        if (parseFloat(this.settingValue) - this.step >= this.settingExtremities[0]) {
            this.settingValue = DIPSwitchListHelper.roundNumber((parseFloat(this.settingValue) - this.step), this.decimalCount);
        }
        this.setCoefficient();
    }

    private plus() {
        if (parseFloat(this.settingValue) + this.step <= this.settingExtremities[1]) {
            this.settingValue = DIPSwitchListHelper.roundNumber((parseFloat(this.settingValue) + this.step), this.decimalCount);
        }
        this.setCoefficient();
    }

    private setMin() {
        this.settingValue = this.settingExtremities[0]
        this.setCoefficient();
    }

    private setMax() {
        this.settingValue = this.settingExtremities[1]
        this.setCoefficient();
    }

    private confirm() {
        let oldValue = this.settings.find(x => x.name === this.settingName).value;
        if (oldValue !== this.settingValue.toString()) {
            let settingPath = this.screen.path.split(":").slice(0, 4).join(":");
            let settingScreen = this.control.screens.find(x => x.path === settingPath);

            this.$store.dispatch("deviceSettingStore/addCacheSettings", { name: this.settingName, value: this.settingValue.toString(), settingName: settingScreen.title });
        } else {
            this.$store.dispatch("deviceSettingStore/removeCacheSettings", this.settingName);
        }
        this.goBack();
    }

    private goBack() {
        this.$router.push({ path: window.location.pathname, query: { displayNav: RouteParamHelper.goBack(<string>this.$route.query.displayNav) } }).catch(() => { });
    }
}