const state = {
    isCultureSelectorVisible: true
};

const getters = {
    isCultureSelectorVisible: state => state.isCultureSelectorVisible
};

const mutations = {
    setIsCultureSelectorVisible: (state, data) => state.isCultureSelectorVisible = data
};

export default {
    namespaced: true,
    state,
    getters,
    mutations
};