import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import HtmlHelper from "@Scripts/Infrastructure/HtmlHelper";
import MediaHelper from "@Scripts/Infrastructure/MediaHelper";

@Component({ name: "Display" })
export default class Display extends Vue {
    private controlStyle: string = null;
    private displayNav: string = null;
    private backgroundImage: string = null;
    private mediaHelper: any = MediaHelper;
    private activeScreen: any = null;

    get settings() {
        return this.$store.getters["deviceSettingStore/settings"];
    }

    get cacheSettings() {
        return this.$store.getters["deviceSettingStore/cacheSettings"];
    }

    get isSettingConfirmVisible() {
        return this.$store.getters["deviceSettingStore/isSettingConfirmVisible"];
    }

    get pin() {
        return this.$store.getters["deviceSettingStore/pin"];
    }

    @Prop() readonly control: any;
    @Prop() readonly isSettingReset: boolean;
    @Prop() readonly isSmallDevice: boolean;
    @Prop() readonly container: HTMLElement;
    @Prop() readonly tripunitimage: HTMLImageElement;

    @Watch("$route.query.displayNav") onRouteQueryDisplayNavChanged(value: string) {
        this.displayNav = value ? value : "Home";

        let newScreen = this.control.screens.find(x => x.path === this.displayNav);
        this.setScreen(newScreen);
    }

    @Watch("container") onContainerChanged(value: any) {
        this.imagesloading();
    }

    @Watch("isSettingReset") onSummaryUpdateChanged(value: boolean) {
        this.$store.dispatch("deviceSettingStore/resetCacheSettings");
        this.$store.commit("deviceSettingStore/setSettings", JSON.parse(JSON.stringify(this.control.settings)));
        this.$router.push({ path: window.location.pathname, query: { displayNav: "Home" } }).catch(() => { });
    }

    private created() {
        this.$store.commit("deviceSettingStore/setSettings", JSON.parse(JSON.stringify(this.control.settings)));

        if (this.$route.query.displayNav) {
            let screen = this.control.screens.find(x => x.path === <string>this.$route.query.displayNav);
            if (screen) {
                this.setScreen(screen);
            }
        }

        if (!this.$route.query.displayNav || !this.control.screens.find(x => x.path === <string>this.$route.query.displayNav)) {
            this.displayNav = "Home";
            this.$router.push({ path: window.location.pathname, query: { displayNav: "Home" } }).catch(() => { });
        }
    }

    private imagesloading() {
        let control = <HTMLElement>this.$refs.control;
        let vm = this;

        HtmlHelper.onAllImagesLoaded(control, x => {
            let controlRect = <DOMRect>control.getBoundingClientRect();

            if (!vm.container) {
                return;
            }
            let positions = HtmlHelper.getPercentagePositions(vm.container, vm.control.position.x, vm.control.position.y, vm.control.size.width, vm.control.size.height);

            vm.controlStyle = "top:" + positions.y + "%; left:" + positions.x + "%; width:" + positions.width + "%; height:" + positions.height + "%;";

            this.$emit("loadComplete", this.control);
        });
    }

    private setScreen(newScreen: any) {
        this.displayNav = newScreen.path;
        this.$router.push({ path: window.location.pathname, query: { displayNav: newScreen.path } }).catch(() => { });

        this.activeScreen = newScreen;

        this.backgroundImage = this.activeScreen.settings.backgroundImage ? this.activeScreen.settings.backgroundImage : null;
        if (this.activeScreen.settings.backgroundImages != null) {
            this.backgroundImage = this.activeScreen.settings.backgroundImages[0];
        }

        this.$store.commit("deviceSettingStore/setSettingConfirmVisible", (this.displayNav.split(":").length <= 2 && this.cacheSettings.length > 0) ? true : false);
        this.$store.commit("deviceSettingStore/setPinVisible", newScreen?.settings?.isProtected ? true : false);
    }

    private changeBackgroundImage(index: number) {
        this.backgroundImage = this.activeScreen.settings.backgroundImages[index];
    }

    private setScreenType(screen: any) {
        switch (screen.type) {
            case "ButtonList": return "app-screen-button-list";
            case "ImageList": return "app-screen-image-list";
            case "SettingList": return "app-screen-setting-list";
            case "ItemList": return "app-screen-item-list";
            case "ItemTable": return "app-screen-item-table";
            case "ListSelector": return "app-screen-list-selector";
            case "NumericSelector": return "app-screen-numeric-selector";
            default: return null;
        }
    }
}