import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import DIPSwitchListHelper from "@Scripts/Infrastructure/DIPSwitchListHelper";
import HtmlHelper from "@Scripts/Infrastructure/HtmlHelper";

@Component({ name: "DIPSwitchList" })
export default class DIPSwitchList extends Vue {
    private configuration: boolean[] = [];
    private controlStyle: string = null;

    @Prop() readonly control: any;
    @Prop() readonly container: HTMLElement;

    @Watch("control.value") onControlValueChanged(value: any) {
        this.configuration = DIPSwitchListHelper.getConfigurationFromValue(this.control, this.control.value);
    }

    @Watch("container") onContainerChanged(value: any) {
        let control = <HTMLElement>this.$refs.control;
        let vm = this;

        HtmlHelper.onAllImagesLoaded(control, x => {
            let controlRect = <DOMRect>control.getBoundingClientRect();

            let positions = HtmlHelper.getPercentagePositions(vm.container, vm.control.position.x, vm.control.position.y, controlRect.width);

            vm.controlStyle = "top:" + positions.y + "%; left:" + positions.x + "%; width:" + positions.width + "%;";

            this.$emit("loadComplete", this.control);
        });
    }

    private created() {
        this.configuration = DIPSwitchListHelper.getConfigurationFromValue(this.control, this.control.value);
    }

    private controlChangeValue(state: any) {
        this.configuration[state.index] = state.isActive;

        this.control.value = DIPSwitchListHelper.getValueFromConfiguration(this.control, this.configuration);

        this.$emit("changeValue", this.control);
    }

    private getGap(index: number): any {
        let gap = null;

        if (this.control.gaps) {
            let gaps = this.control.gaps.filter(x => x.index === index);
            if (gaps.length !== 0) {
                gap = gaps[0];
            }
        }

        return gap;
    }
};