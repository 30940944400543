import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import MediaHelper from "@Scripts/Infrastructure/MediaHelper";
import RouteParamHelper from "@Scripts/Infrastructure/RouteParamHelper";

@Component({ name: "ImageList" })
export default class ImageList extends Vue {
    private mediaHelper: any = MediaHelper;
    private images: any[] = [];
    private image: any = {};
    private imageIndex: number = 0;
    private settingName: string = null;
    private isPagination: boolean = false;
    private unsubscribe: any = null;
    private timeInterval: any = null;

    get pin() {
        return this.$store.getters["deviceSettingStore/pin"];
    }

    get isSettingConfirmVisible() {
        return this.$store.getters["deviceSettingStore/isSettingConfirmVisible"];
    }

    @Prop() readonly screen: any;
    @Prop() readonly isSmallDevice: boolean;

    private created() {
        this.settingName = this.screen.settings?.settingName;
        this.isPagination = this.screen.settings.isPagination ? this.screen.settings.isPagination : false;
        this.images = this.screen.settings.items;
        this.image = this.images[this.imageIndex];

        if (this.image.durationAnimation) {
            this.timeInterval = setTimeout(() => {
                this.$router.push({ path: window.location.pathname, query: { displayNav: this.image.actionValue } }).catch(() => { });
            }, this.image.durationAnimation);
        }

        this.unsubscribe = (<any>this.$store).subscribeAction((action, state) => {
            if ((this.pin.isVisible && !this.pin.isCheked) || this.isSettingConfirmVisible) {
                return;
            }

            if (action.type === "deviceNavigationStore/onPressButtonEnter") {
                this.$router.push({ path: window.location.pathname, query: { displayNav: "Home" } }).catch(() => { });
            } else if (action.type === "deviceNavigationStore/onPressButtonDown") {
                this.back()
            } else if (action.type === "deviceNavigationStore/onPressButtonUp") {
                this.next()
            }
        });
    }

    private beforeDestroy() {
        clearTimeout(this.timeInterval);
        this.unsubscribe();
    }

    private back() {
        if (this.imageIndex - 1 >= 0) {
            this.imageIndex--;
            this.image = this.images[this.imageIndex];
        } else {
            this.imageIndex = this.images.length - 1;
            this.image = this.images[this.imageIndex];
        }
    }

    private next() {
        if (this.imageIndex + 1 < this.images.length) {
            this.imageIndex++;
            this.image = this.images[this.imageIndex];
        } else {
            this.imageIndex = 0;
            this.image = this.images[this.imageIndex];
        }
    }

    private goBack() {
        this.$router.push({ path: window.location.pathname, query: { displayNav: RouteParamHelper.goBack(<string>this.$route.query.displayNav) } }).catch(() => { });
    }
}