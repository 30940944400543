import { DirectiveOptions } from "vue";

const directive: DirectiveOptions = {
    inserted(el, node) {
        let acButton = el.getElementsByClassName("ac-button");
        for (const item of acButton) {
            if (item) {
                item.addEventListener("click", () => {
                    let menu = el.getElementsByClassName("ac-list");
                    for (const menuItem of menu) {
                        if (menuItem) {
                            if (menuItem.classList.contains("show")) {
                                menuItem.classList.remove("show");
                            } else {
                                menuItem.classList.add("show");
                            }
                        }
                    }

                });
            }
        }

        hideOnClickOutside(el);

        function hideOnClickOutside(element) {
            const outsideClickListener = event => {
                if (!element.contains(event.target)) {
                    if (isVisible(element)) {
                        let menu = element.getElementsByClassName("ac-list");
                        for (const menuItem of menu) {
                            if (menuItem) {
                                menuItem.classList.remove("show");
                            }
                        }
                    }
                }
            }
            document.addEventListener("click", outsideClickListener);
        }

        const isVisible = elem => !!elem && !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);
    }
};

export default directive;