import Vue from "vue";
import Component from "vue-class-component";

@Component({ name: "CultureSelector" })
export default class CultureSelector extends Vue {
    get culture() {
        return this.$store.getters["appResourceStore/culture"];
    }

    get cultureTitle() {
        return this.cultures.filter(x => x.value === this.culture)[0].title;
    }

    get cultureTag() {
        return this.cultures.filter(x => x.value === this.culture)[0].tag;
    }

    get cultures() {
        return this.$store.getters["appResourceStore/cultures"];
    }

    get appResources() {
        return this.$store.getters["appResourceStore/appResources"];
    }

    private setCulture(culture) {
        this.$store.commit("appResourceStore/setCulture", culture.value);

        this.$store.dispatch("appResourceStore/getAppResources", culture.value);

        this.$router.push({ path: window.location.pathname, query: { culture: this.culture } }).catch(() => { });

        document.getElementById("culture-selector").classList.remove("show");
    }
};