import Vue from "vue";
import AppConfig from "@Scripts/Config/AppConfig";
import { router } from "@Scripts/Config/RouteConfig";
import { store } from "@Scripts/Config/StoreConfig";

export default class BasePage {
    public static init() {
        AppConfig.AddComponents();
        AppConfig.AddDirectives();

        let vm = new Vue({
            el: "#app",
            router,
            store,
            created() {
                if (this.$route.query.header === "false") {
                    this.$store.commit("headerStore/setIsHeader", false);
                }
                if (this.$route.query.iscultureselectorvisible === "false") {
                    this.$store.commit("cultureSelectorStore/setIsCultureSelectorVisible", false);
                }
            }
        });

        (<any>window).App.instances.vm = vm;

        this.resetCssAnimations();
    }

    private static resetCssAnimations() {
        // Styles animations reset
        (<any>window).addEventListener("load", function () {
            let loaders = document.getElementsByClassName("loader");
            for (let i = 0; i < loaders.length; i++) {
                loaders[i].classList.add("hide");
            }

            let preLoaders = document.querySelectorAll("[data-preload]");
            for (let i = 0; i < preLoaders.length; i++) {
                preLoaders[i].removeAttribute("data-preload");
            }
        });
    }
}