import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import HtmlHelper from "@Scripts/Infrastructure/HtmlHelper";
import RouteParamHelper from "@Scripts/Infrastructure/RouteParamHelper";

@Component({ name: "Button" })
export default class Button extends Vue {
    private controlStyle: string = null;

    get cacheSettings() {
        return this.$store.getters["deviceSettingStore/cacheSettings"];
    }

    get isSettingConfirmVisible() {
        return this.$store.getters["deviceSettingStore/isSettingConfirmVisible"];
    }

    @Prop() readonly control: any;
    @Prop() readonly container: HTMLElement;

    @Watch("container") onContainerChanged(value: any) {
        let control = <HTMLElement>this.$refs.control;
        let vm = this;

        HtmlHelper.onAllImagesLoaded(control, x => {
            let controlRect = <DOMRect>control.getBoundingClientRect();

            let positions = HtmlHelper.getPercentagePositions(vm.container, vm.control.position.x, vm.control.position.y, vm.control.size.width, vm.control.size.height);

            vm.controlStyle = "top:" + positions.y + "%; left:" + positions.x + "%; width:" + positions.width + "%; height:" + positions.height + "%;";

            this.$emit("loadComplete", this.control);
        });
    }

    private setAction(control: any) {
        if (control.actionType === "Navigation") {
            if (this.cacheSettings.length > 0) {
                this.$store.commit("deviceSettingStore/setSettingConfirmVisible", true);
            }

            let displayNav = <string>this.$route.query.displayNav;
            let index = 0;
            if (control.actionValues.length > 0 && control.actionValues.some(x => x === displayNav)) {
                index = control.actionValues.findIndex(x => x === displayNav) + 1 > control.actionValues.length ? 0 : control.actionValues.findIndex(x => x === displayNav) + 1;
            }
            this.$router.push({ path: window.location.pathname, query: { displayNav: control.actionValues[index] } }).catch(() => { });
        }

        if (control.actionType === "Function") {
            switch (control.actionValues[0]) {
                case "Esc":
                    if (this.isSettingConfirmVisible) {
                        return;
                    }

                    this.goBack(true);
                    break;
                case "Enter":
                    this.$store.dispatch("deviceNavigationStore/onPressButtonEnter");
                    break;
                case "Down":
                    this.$store.dispatch("deviceNavigationStore/onPressButtonDown");
                    break;
                case "Up":
                    this.$store.dispatch("deviceNavigationStore/onPressButtonUp");
                    break;
            }
        }
    }

    private goBack(isDeviceSmall: boolean = false) {
        this.$router.push({ path: window.location.pathname, query: { displayNav: RouteParamHelper.goBack(<string>this.$route.query.displayNav, isDeviceSmall) } }).catch(() => { });
    }
}