import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({ name: "TripUnitSearchBox" })
export default class TripUnitSearchBox extends Vue {
    private circuitBreakerItems: any[] = [];
    private familyItems: any[] = [];
    private typeItems: any[] = [];
    private protectionItems: any[] = [];
    private applicationItems: any[] = [];
    private showSearch: boolean = false;

    @Prop() readonly tripUnits: any;

    get culture() {
        return this.$store.getters["appResourceStore/culture"];
    }

    get appResources() {
        return this.$store.getters["appResourceStore/appResources"];
    }

    get searchData() {
        return this.$store.getters["searchStore/searchData"];
    }

    private created() {
        this.initSearchData();
    }

    private initSearchData() {
        if (this.searchData.family) {
            this.getFamilyItems();
        }

        this.searchData.isFilter = (<any>this.$parent).isFilter();

        this.getData();
    }

    private getData() {
        this.getFamilyItems();
        this.setCircuitBreakerItems();
        this.setTypeItems();
        this.setProtectionItems();
        this.setApplicationItems();
    }

    private getFamilyItems() {
        this.familyItems = this.tripUnits.map(x => { return { title: x.family, value: x.family } }).filter((item, i, items) => items.findIndex(t => (t.title === item.title)) === i);
    }

    private setCircuitBreakerItems() {
        this.circuitBreakerItems = this.tripUnits.map(x => { return { title: x.circuitBreaker, value: x.circuitBreaker, family: x.family } })
            .filter((item, i, items) => items.findIndex(t => (t.title === item.title && t.family === this.searchData.family)) === i);
    }

    private setTypeItems() {
        this.typeItems = this.tripUnits.map(x => { return { title: x.type, value: x.type, family: x.family } })
            .filter((item, i, items) => items.findIndex(t => (t.title === item.title && t.family === this.searchData.family)) === i);
    }

    private setProtectionItems() {
        this.protectionItems = this.tripUnits.map(x => { return { title: x.protection, value: x.protection, family: x.family, type: x.type } });

        if (this.searchData.type) {
            this.protectionItems = this.protectionItems.filter((item, i, items) => items.findIndex(t => (t.title === item.title && t.family === this.searchData.family && t.type === this.searchData.type)) === i);
        } else {
            this.protectionItems = this.protectionItems.filter((item, i, items) => items.findIndex(t => (t.title === item.title && t.family === this.searchData.family)) === i);
        }
    }

    private setApplicationItems() {
        this.applicationItems = this.tripUnits.map(x => { return { title: x.application, value: x.application, family: x.family } })
            .filter((item, i, items) => items.findIndex(t => (t.title === item.title && t.family === this.searchData.family)) === i);
    }

    private setStandard(value: string) {
        if (value === this.searchData.standard) {
            this.searchData.standard = null;
        } else {
            this.searchData.standard = value;
        }

        this.updateSearch();
    }

    private updateSearch() {
        this.setCircuitBreakerItems();
        this.setTypeItems();
        this.setProtectionItems();
        this.setApplicationItems();

        this.searchData.isFilter = (<any>this.$parent).isFilter();

        this.$emit("updateSearch");
    }

    private setDefaultSettings() {
        this.$store.commit("searchStore/resetSearchData", this.searchData);
        this.$emit("updateSearch");
    }

    private setShowSearch() {
        this.showSearch = !this.showSearch;
    }

    private resetSearch() {
        this.searchData.circuitBreaker = null;
        this.searchData.standard = null;
        this.searchData.type = null;
        this.searchData.protection = null;
        this.searchData.application = null;

        this.updateSearch();
    }
};