import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import MediaHelper from "@Scripts/Infrastructure/MediaHelper";

@Component({ name: "Chart" })
export default class Chart extends Vue {
    private chartName: string = null;
    private mediaHelper: any = MediaHelper;

    get appResources() {
        return this.$store.getters["appResourceStore/appResources"];
    }

    @Prop() readonly summaryData: any;

    @Watch("summaryData") onSummaryDataChanged(value: any) {
        this.setConfigurationCharName();
    }

    private created() {
        this.setConfigurationCharName();
    }

    private setConfigurationCharName() {
        if (this.summaryData.isProtectionL) {
            if (this.summaryData.protectionL.i1 && this.summaryData.protectionL.i1 > 0) {
                this.chartName = "L1";
            }
        } else {
            this.chartName = "L0";
        }

        if (this.summaryData.isProtectionS) {
            if (this.summaryData.protectionS.i2 && this.summaryData.protectionS.i2 > 0) {
                this.chartName += "_S1";
                if (this.summaryData.protectionS.t2c) {
                    this.chartName += this.summaryData.protectionS.t2c ? "D" : "L";
                } else {
                    this.chartName += "L";
                }
            } else {
                this.chartName += "_S0L";
            }
        } else {
            this.chartName += "_S0L";
        }

        if (this.summaryData.isProtectionI) {
            if (this.summaryData.protectionI.i3 && this.summaryData.protectionI.i3 > 0) {
                this.chartName += "_I1";
            } else {
                this.chartName += "_I0";
            }
        } else {
            this.chartName += "_I0";
        }

        if (this.summaryData.isProtectionU) {
            if (this.summaryData.u != null && this.summaryData.u === "On") {
                this.chartName += "_U1";
            } else {
                this.chartName += "_U0";
            }
        } else {
            this.chartName += "_U0";
        }

        if (this.summaryData.isProtectionG) {
            if (this.summaryData.protectionG.i4 && this.summaryData.protectionG.i4 > 0) {
                this.chartName += "_G1";
                if (this.summaryData.protectionG.t4c) {
                    this.chartName += this.summaryData.protectionG.t4c ? "D" : "L";
                } else {
                    this.chartName += "L";
                }
            } else {
                this.chartName += "_G0L";
            }
        } else {
            this.chartName += "_G0L";
        }
    }

    private imageLoadError() {
        this.chartName = null;
    }
};